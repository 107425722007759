import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Index = () => {
  return (
    <div>
        <Helmet>
          <title>FIR Management System</title>
        </Helmet>
        <div className='container py-100 main-home-link'>
            <div className="row g-3">
                <div className="col-12 main-home-link">
                    <div>
                        <div className="form-title">
                            <h1>DAR</h1>
                        </div>
                        <div className='form-border'>
                          <p><Link to={"/FormOne"}><i class="fa-solid fa-arrow-right"></i> First Accident Report (FAR)</Link></p>  
                          <p><Link to={"/FormTwo"}><i class="fa-solid fa-arrow-right"></i> Rights Of Victim(S) Of Road Accidentand Flow Chart Of This Scheme</Link></p>  
                          <p><Link to={"/FormThree"}><i class="fa-solid fa-arrow-right"></i> Driver's Form</Link></p>  
                          <p><Link to={"/FormFour"}><i class="fa-solid fa-arrow-right"></i> Owner's Form</Link></p>  
                          <p><Link to={"/FormFive"}><i class="fa-solid fa-arrow-right"></i> Interim Accident Report (IAT)</Link></p>  
                          <p><Link to={"/FormSixA"}><i class="fa-solid fa-arrow-right"></i>  Victim's Form</Link></p>  
                          <p><Link to={"/FormSixB"}><i class="fa-solid fa-arrow-right"></i>  Victim's form relating to minor children of vitim(s)</Link></p>  
                          <p><Link to={"/FormSeven"}><i class="fa-solid fa-arrow-right"></i> Detailed Accident Report (DAR)</Link></p>  
                          <p><Link to={"/FormEight"}><i class="fa-solid fa-arrow-right"></i> Site Plan</Link></p>  
                          <p><Link to={"/FormNine"}><i class="fa-solid fa-arrow-right"></i> Mechanical Inspection Report</Link></p>  
                          <p><Link to={"/FormTen"}><i class="fa-solid fa-arrow-right"></i> Verification Report</Link></p>  
                          <p><Link to={"/FormEleven"}><i class="fa-solid fa-arrow-right"></i> Insurance Form</Link></p>  
                          <p><Link to={"/FormTwelve"}><i class="fa-solid fa-arrow-right"></i> Victim Impact Report</Link></p>  
                          <p><Link to={"/FormThirteen"}><i class="fa-solid fa-arrow-right"></i> Format Of Written Submissions To Be Filed By Parties In Death Cases</Link></p>  
                          <p><Link to={"/FormFourteen"}><i class="fa-solid fa-arrow-right"></i> Format Of Written Submissions To Be Filed By The Parties Ininjury Cases</Link></p>  
                          <p><Link to={"/FormFifteen"}><i class="fa-solid fa-arrow-right"></i> Summary Of Computation Of Award Amount In Death Cases To Be Incorporated In The Award</Link></p>  
                          <p><Link to={"/FormSixteen"}><i class="fa-solid fa-arrow-right"></i> Summary Of The Computation Of Award Amount In Injury Cases To Be Incorporated In The Award</Link></p>  
                          <p><Link to={"/FormSeventeen"}><i class="fa-solid fa-arrow-right"></i> Compliance Of The Provisions Of The Scheme To Be Mentioned In The Award</Link></p>  
                          <p><Link to={"/FormEighteen"}><i class="fa-solid fa-arrow-right"></i> Format Of Record Of Awards To Be Maintained By The Claims Tribunal</Link></p>  
                          <p className='mb-0'><Link to={"/FormNineteen"}><i class="fa-solid fa-arrow-right"></i> Motor Accident Claims Annuity Deposit (Macad) Scheme</Link></p>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index