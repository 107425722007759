import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormFive = () => {
    
    const fileInputRef0 = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
    const fileInputRef5 = useRef(null);
    const fileInputRef6 = useRef(null);

    const [fileName0, setFileName0] = useState('');
    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');
    const [fileName3, setFileName3] = useState('');
    const [fileName4, setFileName4] = useState('');
    const [fileName5, setFileName5] = useState('');
    const [fileName6, setFileName6] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Interim Accident Report (IAT) - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Interim Accident Report (IAT)</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of Accident</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Time of Accident</label>
                            <input type="time" className="form-control" id="" value="10:10"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Place of Accident</label>
                            <input type="text" className="form-control" id="" placeholder="Gandhinagar"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Offending Vehicle</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Registration No.</label>
                            <input type="text" className="form-control" id="" placeholder="001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle Make</label>
                            <input type="text" className="form-control" id="" placeholder="Vehicle Make"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle Model</label>
                            <input type="text" className="form-control" id="" placeholder="2010"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Driver of the offending vehicle</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father's Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Driving Licence</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Permanent</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Learner’s</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Juvenile</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Without License</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label" for="flexRadioDefault5">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Driving Licence No.</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Validity of Licence</label>
                            <input type="text" className="form-control" id="" placeholder="10 Years"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Licensing Authority</label>
                            <input type="text" className="form-control" id="" placeholder="Mr. Ramprakash"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Owner of the offending vehicle</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father's Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>In case of commercial vehicle</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Permit details</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Fitness details</label>
                            <input type="text" className="form-control" id="" placeholder="10 Years"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Insurance Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Insurance Policy No.</label>
                            <input type="text" className="form-control" id="" placeholder="11001100"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Insurance Policy</label>
                            <input type="text" className="form-control" id="" placeholder="10 Year"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="RK Insurance Company"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address of Insurance Company</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Witness(es) to the accident</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Witness-1 :</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Witness-2 :</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Witness-3 :</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Witness-4 :</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Brief description of the Accident</label>
                            <TextEditor />
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of compliance(s)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of filing of First Accident Report  (FAR)</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of uploading FAR on the website of Delhi Police</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of delivery of FIR and FAR to the Insurance Company</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of delivery of FIR, Form-II and FAR to the Victim(s)</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of receipt of Form-III from the Driver</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of receipt of Form-IV from the Owner</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of delivery of Form-III and Form-IV to the Insurance Company</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of delivery of Form-III and Form-IV to the Victim(s)</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>  
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the information/documents of the driver/owner have been verified. <i>( If yes, attach the Verification Report. )</i></label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault6" />
                                    <label className="form-check-label" for="flexRadioDefault6">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault7" />
                                    <label className="form-check-label" for="flexRadioDefault7">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>S.H.O./I.O</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.I.S. No.</label>
                            <input type="number" className="form-control" id="" placeholder="0001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Phone No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.S.</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Documents to be attached</p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(1) First Accident Report (FAR)</label><br />
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(2) Driver’s Form-II along with documents submitted by the Driver</label><br />
                            <input type="file" ref={fileInputRef2} onChange={(e) => handleFileChange(e, setFileName2)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef2)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName2 && (
                                <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(3) Owner’s Form-III along with documents submitted by the Owner</label><br />
                            <input type="file" ref={fileInputRef3} onChange={(e) => handleFileChange(e, setFileName3)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef3)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName3 && (
                                <p className="mt-2 file-pre">Selected file: {fileName3}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(4) Verification Report</label><br />
                            <input type="file" ref={fileInputRef4} onChange={(e) => handleFileChange(e, setFileName4)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef4)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName4 && (
                                <p className="mt-2 file-pre">Selected file: {fileName4}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormSixA"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormFive