import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FormSeventeen = () => {
    
    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };
    
  return (
      <div>
      <Helmet>
          <title>Format Of Record Of Awards To Be Maintained By The Claims Tribunal - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Format Of Record Of Awards To Be Maintained By The Claims Tribunal</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of award</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Case number</label>
                            <input type="number" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Title of the case</label>
                            <input type="text" className="form-control" id="" placeholder='Title'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Award amount</label>
                            <input type="number" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of notice of deposit by the depositor to the Claimant(s)</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of notice of deposit by the Tribunal to the Claimant(s)</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Amount of interest upto date of notice of deposit</label>
                            <input type="number" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Amount deposited along with date of deposit</label>
                            <input type="number" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Amount of interest upto date of notice of deposit</label>
                            <input type="number" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Whether entire award amount and interest deposited. If no, balance outstanding award amount/interest</label>
                            <input type="text" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Action taken to recover the balance award interest</label>
                            <input type="text" className="form-control" id="" placeholder='110'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of release of the award amount to the Claimant(s)</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Mode of release of the award amount: (Give the details of endorsement made on the cheques) </label>
                            <textarea class="form-control" id="" rows="3" placeholder=''></textarea>
                        </div>
                        <div className="col-12 input-space mb-0">
                            <label for="" className="form-label">Remarks</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Remarks'></textarea>
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormNineteen"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormSeventeen