import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Index = () => {
  return (
    <div>
        <Helmet>
          <title>FIR Management System</title>
        </Helmet>
        <div className='container py-100'>
            <div className="row g-3">
              <div className="col-12 main-home-link">
                <div className="form-title">
                  <h1>FORM OF FIR MANAGEMENT SYSTEM</h1>
                </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/BNS"} >
                      <div className='all-fir-form'>
                        <p>BNS FORM</p>
                        <h6>10</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/DAR"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
              <div className="col-md-6 col-12">
                  <div className="">
                    <Link to={"/"} >
                      <div className='all-fir-form'>
                        <p>DAR FORM</p>
                        <h6>20</h6>
                      </div>
                    </Link>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Index