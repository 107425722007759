import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FormNineteen = () => {
    return (
        <div>
            <Helmet>
                <title>Motor Accident Claims Annuity Deposit (Macad) Scheme - FIR Management System</title>
            </Helmet>
            <div className='container'>
                <div className='pt-3 text-end'>
                    <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
                </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Motor Accident Claims Annuity Deposit (Macad) Scheme</h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-12 input-space mb-0">
                                <div className="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "10%" }}>Sr. No. </th>
                                            <th scope="col" style={{ width: "30%" }}>Scheme Features </th>
                                            <th scope="col" style={{ width: "60%" }}>Particulars/Details  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Purpose </td>
                                            <td><p>
                                                One time lump sum amount, as decided by the Court / Tribunal, deposited to receive the same in Equated Monthly Installments
                                                (EMIs), comprising a part of the principal amount as well as interest.
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Eligibility </td>
                                            <td><p>
                                                Individuals including Minors through guardian in single name.
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Mode of Holding </td>
                                            <td><p>
                                                Singly
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Type of account  </td>
                                            <td><p>
                                                Motor Accident Claims Annuity (Term) Deposit Account (MACAD)
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Deposit Amount  </td>
                                            <td><ol  className="p-3" type='i'>
                                                <li>Maximum: No Limit </li>
                                                <li>Minimum – Based on minimum monthly annuity Rs. 1,000/- for the relevant period.</li>
                                            </ol></td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Tenure </td>
                                            <td><ol  className="p-3" type='i'>
                                                <li>36 to 120 months</li>
                                                <li>In case the period is less than 36 months, normal FD will be opened.</li>
                                                <li>MACAD for longer period (more than 120 months) will be looked as per direction of the Court.</li>
                                            </ol></td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Rate of interest  </td>
                                            <td><p>
                                                Prevailing rate of interest as per Tenure.
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Receipts/Advices </td>
                                            <td><ol  className="p-3" type='i'>
                                                <li>No Receipts will be issued to depositors. </li>
                                                <li>Passbook will be issued for MACAD</li>
                                            </ol></td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Loan Facility  </td>
                                            <td><p>
                                                No loan or advances shall be allowed.
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Nomination facility  </td>
                                            <td><ol  className="p-3" type='i'>
                                                <li>Available. </li>
                                                <li>MACAD shall be duly nominated as directed by the Court. </li>
                                            </ol></td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Premature Payment  </td>
                                            <td><ol  className="p-3" type='i'>
                                                <li>Premature closure or part lump sum payment of MACAD during the life of the claimant will be made with permission of the 
                                                     court. However, if permitted, the annuity part will be reissued for balance tenure and amount, if any, with change in 
                                                      annuity amount. </li>
                                                <li>Premature closure penalty will not be charged. </li>
                                                <li>In case of death of the claimant, payment to be given to the nominee. The nominee has an option to continue with the 
                                                     annuity or seek pre-closure.</li>
                                            </ol></td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Tax deduction at source </td>
                                            <td><ol  className="p-3" type='i'>
                                                <li>Interest payment is subject to TDS as per Income Tax Rules. Form 15G/15H can be submitted by the Depositor to exemption 
                                                     from the Tax deduction. </li>
                                                <li>The annuity amount on monthly basis net of TDS, will be credited to the MACT Savings Bank account. </li>
                                            </ol></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/DAR"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormNineteen