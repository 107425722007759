import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import { Helmet } from 'react-helmet';

const FormFifteen = () => {

    const [representatives, setRepresentatives] = useState([
        { name: '', relationship: '', age: '' },
    ]);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newRepresentatives = [...representatives];
        newRepresentatives[index][name] = value;
        setRepresentatives(newRepresentatives);
    };

    const addRepresentative = () => {
        setRepresentatives([...representatives, { name: '', relationship: '', age: '' }]);
    };

    const removeRepresentative = (index) => {
        const newRepresentatives = representatives.filter((_, i) => i !== index);
        setRepresentatives(newRepresentatives);
    };

    return (
        <div>
            <Helmet>
                <title>Summary Of Computation Of Award Amount In Death Cases To Be Incorporated In The Award - FIR Management System</title>
            </Helmet>
            <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Summary Of Computation Of Award Amount In Death Cases To Be Incorporated In The Award</h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date of accident </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label"> Name of the deceased</label>
                                <input type="text" className="form-control" id="" placeholder="Mukesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age of the deceased </label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Income of the deceased </label>
                                <input type="number" className="form-control" id="" placeholder="Enter monthly income" />
                            </div>                           
                            <div className="entry">
                                <p className='mb-32'>Name, age and relationship of legal representatives of deceased:</p>
                            </div>
                            <div className="col-12 input-space">
                                {representatives.map((rep, index) => (
                                    <div key={index} className="d-flex flex-column input-space mb-0">
                                        <div className='row '>
                                        <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={rep.name}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Relationship</label>
                                                <input
                                                    type="text"
                                                    name="relationship"
                                                    placeholder="Relationship"
                                                    value={rep.relationship}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Age</label>
                                                <input
                                                    type="number"
                                                    name="age"
                                                    placeholder="Age"
                                                    value={rep.age}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>                                      
                                            <div className="col-md-3 d-flex justify-content-start align-items-center mb-3 mt-md-3 mt-0">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-3"
                                                    onClick={() => removeRepresentative(index)}><i class="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={addRepresentative}>
                                    Add Another Representative
                                </button>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Computation of Compensation </p>
                            </div>
                            <div className="col-12 input-space mb-0">
                                <div className="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60%" }}>Heads </th>
                                            <th scope="col" style={{ width: "40%" }}>Awarded by the Claims Tribunal </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Income of the deceased (A) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Add-Future Prospects (B)  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Less-Personal expenses of the deceased (C)  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Monthly loss of dependency [(A+B) – C = D] </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Annual loss of dependency (D x 12) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Multiplier (E) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total loss of dependency (D x 12 x E = F) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Medical Expenses (G) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for loss of consortium (H) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for love and affection (I) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for loss of estate (J) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation towards funeral expenses (K) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL COMPENSATION (F + G + H + I + J + K =L) </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>RATE OF INTEREST AWARDED </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>Interest amount up to the date of award (M) </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>Total amount including interest (L+M) </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>Award amount released </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>Award amount kept in FDRs </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>Mode of disbursement of the award amount to the claimant(s). </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>Next Date for compliance of the award. </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/FormSixteen"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormFifteen