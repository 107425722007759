import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import { Helmet } from 'react-helmet';

const ArrestMemo = () => {
    
    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick1 = () => {
        fileInputRef1.current.click();
    };

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName1(file.name);
        }
    };
    
  return (
      <div>
        <Helmet>
            <title>Arrest Memo - FIR Management System</title>
        </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>arrest Memo</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">suspect </label>
                            <input type="text" className="form-control" id="" placeholder="Pawan Pachauri"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">suspect address (present time)</label>
                            <input type="text" className="form-control" id="" placeholder="Ram Nagar, Motiram Road, Shahdara, Delhi- 110032"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">suspect address (permanent)</label>
                            <input type="text" className="form-control" id="" placeholder="Ram Nagar, Motiram Road, Shahdara, Delhi- 110032"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">place of arrest</label>
                            <input type="text" className="form-control" id="" placeholder="Ram Nagar, Motiram Road"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR NO/DD NO/SEC OF LAW </label>
                            <input type="text" className="form-control" id="" placeholder="16190535"/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="" className="form-label">arrest Date </label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="" className="form-label">Arrest Time</label>
                            <input type="time" className="form-control" id="" placeholder="0040A"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">name of convey the arrest information</label>
                            <input type="text" className="form-control" id="" placeholder="Pawan Pachauri"/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">officer who arrested the suspect</label>
                            <input type="text" className="form-control" id="" placeholder="ASI Mukesh"/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="entry">
                            <p className='mb-32'>witness</p>
                        </div>
                        <div className="col-md-6 input-space mb-2">
                            <input type="text" className="form-control" id="" placeholder="ASI Mukesh"/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 input-space mb-2">
                            <input type="text" className="form-control" id="" placeholder="ASI Mukesh"/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 input-space">
                            <input type="text" className="form-control" id="" placeholder="ASI Mukesh"/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 input-space">
                            <div className="entry">
                                <p>signature of arrestee</p>
                            </div>
                            {<SignatureComponent/>}
                        </div>
                        <div className="col-md-6 input-space">
                            <div className="entry">
                                <p>signature of the I.O</p>
                            </div>
                            {<SignatureComponent/>}
                        </div>
                        <div className="col-12">
                            <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={handleFileChange1}
                                style={{ display: 'none' }}
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleUploadClick1}
                            >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/PersonalSearchMemo"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default ArrestMemo