import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormNine = () => {
    
    const fileInputRef0 = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
    const fileInputRef5 = useRef(null);
    const fileInputRef6 = useRef(null);

    const [fileName0, setFileName0] = useState('');
    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');
    const [fileName3, setFileName3] = useState('');
    const [fileName4, setFileName4] = useState('');
    const [fileName5, setFileName5] = useState('');
    const [fileName6, setFileName6] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Mechanical Inspection Report - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Mechanical Inspection Report</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of Mechanical Inspection</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Motor Vehicle Inspector</label>
                            <input type="text" className="form-control" id="" placeholder="Ramesh Gupta"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Registration No. of Motor Vehicle Inspector</label>
                            <input type="number" className="form-control" id="" placeholder='202412'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle Registration No.</label>
                            <input type="number" className="form-control" id="" placeholder='001001'/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Vehicle Type</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Motorized 2-wheeler  </label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Auto</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Car/Jeep/Taxi</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Cycle Rickshaw</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label" for="flexRadioDefault5">Hand Drawn Cart</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                    <label className="form-check-label" for="flexRadioDefault6">Bicycle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                                    <label className="form-check-label" for="flexRadioDefault7">Tempo/Tractor</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                                    <label className="form-check-label" for="flexRadioDefault8">Truck/Lorry</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" />
                                    <label className="form-check-label" for="flexRadioDefault9">Drawn Cart</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" />
                                    <label className="form-check-label" for="flexRadioDefault10">Bus</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                    <label className="form-check-label" for="flexRadioDefault11">Heavy Articulated Vehicle/ Trolley</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault12" />
                                    <label className="form-check-label" for="flexRadioDefault12">Not Known</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault13" />
                                    <label className="form-check-label" for="flexRadioDefault13">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle make</label>
                            <input type="text" className="form-control" id="" placeholder="Vehicle make"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Model Name</label>
                            <input type="text" className="form-control" id="" placeholder="Hiro Honda"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Colour of vehicle</label>
                            <input type="text" className="form-control" id="" placeholder="Black"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Engine Number</label>
                            <input type="text" className="form-control" id="" placeholder="101010101010"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Chassis Number</label>
                            <input type="text" className="form-control" id="" placeholder="101010101010"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Location of vehicle inspection</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Accident Site</label>
                            <input type="text" className="form-control" id="" placeholder="Accident Site"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Garage</label>
                            <input type="text" className="form-control" id="" placeholder="Garage"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Other (Specify)</label>
                            <input type="text" className="form-control" id="" placeholder="Other (Specify)"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>In case of Commercial Vehicle</p>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Details of Fitness</label>
                            <input type="text" className="form-control" id="" placeholder="Details of Fitness"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Details of permit</label>
                            <input type="text" className="form-control" id="" placeholder="Details of permit"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Evidence of Impact 1 (Paint Transfer)</p>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Paint Transfer found</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault14" />
                                    <label className="form-check-label" for="flexRadioDefault14">Yes  </label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault15" />
                                    <label className="form-check-label" for="flexRadioDefault15">No  </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Colour of Paint Transfer</label>
                            <input type="text" className="form-control" id="" placeholder="Colour of Paint Transfer"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Location of Paint Transfer</label>
                            <input type="text" className="form-control" id="" placeholder="Location of Paint Transfer"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Evidence of Impact 2 (Scratch marks/ Others)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Type of scratch</label>
                            <input type="text" className="form-control" id="" placeholder="Type of scratch"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Location of paint transfer</label>
                            <input type="text" className="form-control" id="" placeholder="Location of paint transfer"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Point of Impact</label>
                            <input type="text" className="form-control" id="" placeholder="Point of Impact"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Mechanical condition of Vehicle</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Steering</label>
                            <input type="text" className="form-control" id="" placeholder="Steering"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Wheels</label>
                            <input type="text" className="form-control" id="" placeholder="Wheels"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Wipers</label>
                            <input type="text" className="form-control" id="" placeholder="Wipers"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mirrors</label>
                            <input type="text" className="form-control" id="" placeholder="Mirrors"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Others</label>
                            <input type="text" className="form-control" id="" placeholder="Others"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Whether vehicle modified by</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Installing CNG/LPG Kit</label>
                            <input type="text" className="form-control" id="" placeholder="Installing CNG/LPG Kit"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Change of vehicle body</label>
                            <input type="text" className="form-control" id="" placeholder="Change of vehicle body"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Condition of Tyres</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault16" />
                                    <label className="form-check-label" for="flexRadioDefault16">Original</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault17" />
                                    <label className="form-check-label" for="flexRadioDefault17">Retreaded </label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Horn</p>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether installed</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault18" />
                                    <label className="form-check-label" for="flexRadioDefault18">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault19" />
                                    <label className="form-check-label" for="flexRadioDefault19">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">If yes, whether functional</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault20" />
                                    <label className="form-check-label" for="flexRadioDefault20">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault21" />
                                    <label className="form-check-label" for="flexRadioDefault21">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Brake lights & other lights functional</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault22" />
                                    <label className="form-check-label" for="flexRadioDefault22">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault23" />
                                    <label className="form-check-label" for="flexRadioDefault23">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether vehicle had faulty number plate</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault24" />
                                    <label className="form-check-label" for="flexRadioDefault24">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault25" />
                                    <label className="form-check-label" for="flexRadioDefault25">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Status of Airbags</p>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the vehicle	fitted with airbags</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault26" />
                                    <label className="form-check-label" for="flexRadioDefault26">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault27" />
                                    <label className="form-check-label" for="flexRadioDefault27">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">If yes, whether airbags were deployed</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault8" id="flexRadioDefault28" />
                                    <label className="form-check-label" for="flexRadioDefault28">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault8" id="flexRadioDefault29" />
                                    <label className="form-check-label" for="flexRadioDefault29">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">For educational institution bus, whether the vehicle was fitted with the doors that can be shut & whether the vehicle had a suitable inscription to indicate that they are in the duty of an educational institute</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether vehicle had tinted glasses</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault9" id="flexRadioDefault30" />
                                    <label className="form-check-label" for="flexRadioDefault30">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault9" id="flexRadioDefault31" />
                                    <label className="form-check-label" for="flexRadioDefault31">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Speed Limiter Devices in cases of PSVs (Commercial Vehicles)</p>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether vehicle fitted with Speed Limiter</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault32" />
                                    <label className="form-check-label" for="flexRadioDefault32">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault33" />
                                    <label className="form-check-label" for="flexRadioDefault33">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">If yes, whether functional</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                <input className="form-check-input" type="radio" name="flexRadioDefault11" id="flexRadioDefault34" />
                                    <label className="form-check-label" for="flexRadioDefault34">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                <input className="form-check-input" type="radio" name="flexRadioDefault11" id="flexRadioDefault35" />
                                    <label className="form-check-label" for="flexRadioDefault35">No </label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Parking Sensors</p>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether	Rear Parking Sensors installed</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault12" id="flexRadioDefault36" />
                                    <label className="form-check-label" for="flexRadioDefault36">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault12" id="flexRadioDefault37" />
                                    <label className="form-check-label" for="flexRadioDefault37">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">If yes, whether functional</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault13" id="flexRadioDefault38" />
                                    <label className="form-check-label" for="flexRadioDefault38">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault13" id="flexRadioDefault39" />
                                    <label className="form-check-label" for="flexRadioDefault39">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Vehicle Location Tracking (VLT) Devices</p>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether installed</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault40" />
                                    <label className="form-check-label" for="flexRadioDefault40">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault41" />
                                    <label className="form-check-label" for="flexRadioDefault41">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space-check">
                            <label for="inputEmail4" className="form-label">If yes, whether functional</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault15" id="flexRadioDefault42" />
                                    <label className="form-check-label" for="flexRadioDefault42">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault15" id="flexRadioDefault43" />
                                    <label className="form-check-label" for="flexRadioDefault43">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Description of damage (including internal & external damage and estimated cost of damage)</label>
                            <TextEditor />
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Documents to be attached</p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">1. Photographs of the vehicle</label><br />
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} multiple/>
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                        <div className="col-md-6 input-space ">
                            <label className="form-label">Motor Vehicle Inspector </label>
                            {<SignatureComponent/>}
                        </div>
                        <div className="col-md-6 input-space mb-0">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormTen"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormNine