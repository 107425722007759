import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FirstInformationReport = () => {

    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick1 = () => {
        fileInputRef1.current.click();
    };

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName1(file.name);
        }
    };
    
  return (
      <div>
        <Helmet>
            <title>First Information Report - FIR Management System</title>
        </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1>First Information Report</h1>
                        <p>Under Section 154 cr.P.C</p>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">District</label>
                            <select id="inputState" className="form-select">
                            <option selected>North</option>
                            <option>North</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">ACT</label>
                            <select id="inputState" className="form-select">
                            <option selected>IPC</option>
                            <option>IPC</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Offence</label>
                            <input type="text" className="form-control" id="" placeholder="Pawan Pachauri"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Type Of Information</label>
                            <input type="text" className="form-control" id="" placeholder="General"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Place Of Occurrence</label>
                            <input type="text" className="form-control" id="" placeholder="Ram Nagar, Motiram Road, Shahdara, Delhi- 110032"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Complaint / Informant</label>
                            <input type="text" className="form-control" id="" placeholder="ASI Mukesh"/>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Details Of Suspect</label>
                            <TextEditor />
                        </div>
                        <div className="col-12">
                            <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={handleFileChange1}
                                style={{ display: 'none' }}
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleUploadClick1}
                            >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/GeneralDiaryGD"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FirstInformationReport