import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import { Helmet } from 'react-helmet';

const FormTwo = () => {
    const fileInputRef2 = useRef(null);
    const [fileName2, setFileName2] = useState(null);

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Rights Of Victim(S) Of Road Accidentand Flow Chart Of This Scheme - FIR Management System</title>
            </Helmet>
            <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Rights Of Victim(S) Of Road Accidentand Flow Chart Of This Scheme </h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row">
                                <div className="entry">
                                    <p className='mb-32'>To be handed over by IO to the Victim/Family Members/Legal Representatives within 10 days of the accident </p>
                                </div>
                                <div className="col-12 input-space">
                                    <div className="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: "7%" }}>Sr. No. </th>
                                                <th scope="col" style={{ width: "93%" }}>RIGHTS OF VICTIM(S) OF ROAD ACCIDENTAND</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td><p>
                                                    Right to immediate medical aid and treatment.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><p>
                                                    Right to copy of FIR.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td><p>
                                                    Right to copy of First Accident Report (FAR) in Form - I.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td><p>
                                                    Right to copy of Rights of Victim and Flow Chart of this Scheme in Form -II.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td><p>
                                                    Right to copy of Driver’s Form-III along with the documents.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td><p>
                                                    Right to copy of Owner’s Form-IV along with the documents.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td><p>
                                                    Right to copy of Interim Accident Report (IAR) in Form-V along with the documents.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td><p>
                                                    Right to blank copy of format of Victim’s Form-VIA and Form-VIB.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td><p>
                                                    Right to copy of Detailed Accident Report (DAR) in Form-VII along with the documents.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td><p>
                                                    Right to copy of Insurance Form-XI.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td><p>
                                                    Right to copy of Report under Section 173 Cr.P.C.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td><p>
                                                    Right to copy of Victim Impact Report in Form-XII.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td><p>
                                                    Right to copy of MLC and Post-Mortem Report.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td><p>
                                                    Right to free legal aid from Delhi State Legal Services Authority.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td><p>
                                                    Right to appear before the Claims Tribunal in person or through lawyer.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td><p>
                                                    Right of a minor child/children (18 years or below) of the victim to be referred to the Child Welfare Committee by the IO for
                                                    Inquiry into their needs and status.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td><p>
                                                    Right of a minor child/children (18 years or below) of the victim to have the Child Welfare Committee conduct an Inquiry
                                                    through
                                                    the District Child Protection Officer into their well-being, medical needs, security, nutrition etc.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td><p>
                                                    Right of a minor child/children (18 years or below) of the victim to get all benefits of Juvenile Justice (Care and rotection
                                                    of
                                                    Children) Act, 2015 in case the Child Welfare Committee returns a finding of a child being a Child in Need of Care and
                                                    Protection (CNCP).
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>19</td>
                                                <td><p>
                                                    Right of such minor child/children of the Victim to be placed in a Children’s Home in case both the parents died or the
                                                    surviving
                                                    parent is unable to take care of the child, as provided under the Juvenile Justice (Care and Protection of Children) Act,
                                                    2015.
                                                </p></td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                                <td><p>
                                                    Right to receive compensation under the Scheme for Motor Accident Claims formulated by the Delhi High Court.
                                                </p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div className="entry">
                                    <p className='mb-32'>Flow Chart of this Scheme is attached. </p>
                                </div>
                                <div className="col-12 input-space">
                                    <input type="file" ref={fileInputRef2} onChange={(e) => handleFileChange(e, setFileName2)} style={{
                                        display:
                                            'none'
                                    }} multiple />
                                    <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef2)} >
                                        <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                    </button>
                                    {fileName2 && (
                                        <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                                    )}
                                </div>
                                <div className="entry">
                                    <p className='mb-32'>S.H.O./I.O</p>
                                </div>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">P.I.S. No.</label>
                                    <input type="number" className="form-control" id="" placeholder="0001" />
                                </div>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">Phone No.</label>
                                    <input type="number" className="form-control" id="" placeholder="+919999999999" />
                                </div>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">P.S.</label>
                                    <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi" />
                                </div>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">Date</label>
                                    <input type="date" className="form-control" id="" value="2024-09-21" />
                                </div>
                                <div className="col-md-6 input-space">
                                    {<SignatureComponent />}
                                </div>
                                <div className="entry">
                                    <p className='mb-32'>Acknowledgement of the Victim/Family Members/Legal Representatives </p>
                                </div>
                                <div className="entry">
                                    <p className='mb-32'>I have received this Form and the Flow Chart of the Scheme along with the copy of a blank Victim’s Form-VIA and
                                        Form-VIB</p>
                                </div>
                                <div className="col-md-6 input-space">
                                    {<SignatureComponent />}
                                </div>
                                <div className="entry">
                                    <p className='mb-32'>Victim/Family Members/Legal Representatives</p>
                                </div>
                                <div className="col-md-6 input-space mb-0">
                                    <label for="" className="form-label">Date</label>
                                    <input type="date" className="form-control" id="" value="2024-09-21" />
                                </div>
                            </div>
                            <div className="col-12 mt-80">
                                <Link to={"/FormThree"} type="submit" className="btn btn-sec">
                                    <i className="fa-solid fa-check ne-2"></i> Save and Submit
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormTwo