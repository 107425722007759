//component
import React, { useState } from "react";
import { Editor } from "primereact/editor";
import { Link } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';           
import 'primeicons/primeicons.css';                         
import 'quill/dist/quill.snow.css';  

export default function TextEditor() {
    const [text, setText] = useState('');
    const customHeader = (
        <div id="toolbar" className="d-flex align-items-center justify-content-between">
            <div>
                <span className="ql-formats">
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <button className="ql-strike"></button>
                </span>
                <span className="ql-formats">
                    <button className="ql-list" value="ordered"></button>
                    <button className="ql-list" value="bullet"></button>
                    <select className="ql-align"></select>
                </span>
            </div>
            <div className="mic">
                <Link to={"#"}><i class="fa-solid fa-microphone"></i></Link>
            </div>
            {/* <span className="ql-formats">
                <button className="ql-link"></button>
                <button className="ql-image"></button>
            </span> */}
        </div>
    );

    return (
        <div>
            <Editor 
                value={text} 
                onTextChange={(e) => setText(e.htmlValue)} 
                style={{ height: '200px' }} 
                headerTemplate={customHeader} 
            />
        </div>
    );
}