import React, { useRef, useEffect } from 'react';
import SignaturePad from 'signature_pad';

const SignatureComponent = () => {
  const canvasRef = useRef(null);
  let signaturePad = null;

  useEffect(() => {
    const canvas = canvasRef.current;
    signaturePad = new SignaturePad(canvas);

    const resizeCanvas = () => {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d').scale(ratio, ratio);
      signaturePad.clear();
    };

    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <div>
      <canvas
        ref={canvasRef}
        className="signature-pad"
      ></canvas>
    </div>
  );
};

export default SignatureComponent;
