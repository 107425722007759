import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FormSeventeen = () => {
    
    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };
    
  return (
      <div>
      <Helmet>
          <title>Compliance Of The Provisions Of The Scheme To Be Mentioned In The Award - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Compliance Of The Provisions Of The Scheme To Be Mentioned In The Award</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of the accident</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of filing of <i><b>Form-I - First Accident Report (FAR)</b></i></label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of delivery of <i><b>Form-II </b></i> to the victim(s)</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of receipt of <i><b>Form-III </b></i> from the Driver</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of receipt of <i><b>Form-IV </b></i> from the Owner</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of filing of the <i><b>Form-V-Interim Accident Report (IAR)</b></i></label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of receipt of <i><b>Form-VIA </b></i> and <i><b>Form-VIB </b></i>from the Victim(s)</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of filing of <i><b>Form-VII - Detailed Accident Report (DAR)</b></i></label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether there was any delay or deficiency on the part of the Investigating Officer? If so, whether any action/ direction warranted?</label>
                            <input type="text" className="form-control" id="" />
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of appointment of the Designated Officer by the Insurance Company</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether the Designated Officer of the Insurance Company submitted his report within 30 days of the DAR?</label>
                            <input type="text" className="form-control" id="" />
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether there was any delay or deficiency on the part of the Designated Officer of the Insurance Company? If so, whether any action/direction warranted?</label>
                            <input type="text" className="form-control" id="" />
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of response of the claimant(s) to the offer of the Insurance Company</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of the award</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Whether the claimant(s) was/were directed to open savings bank account(s) near their place of residence?</label>
                            <input type="text" className="form-control" id=""/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of order by which claimant(s) was/were directed to open savings bank account(s) near his place of residence and produce PAN Card and Aadhaar Card and the direction to the bank not issue any cheque book/debit card to the claimant(s) and make an endorsement to this effect on the passbook</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date on which the claimant(s) produced the passbook of their savings bank account near the place of their residence along with the endorsement, PAN Card and Adhaar Card?</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Permanent Residential Address of the claimant(s)</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Whether the claimant(s) savings bank account(s) is near his place of residence?</label>
                            <input type="text" className="form-control" id=""/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Whether the claimant(s) was/were examined at the time of passing of the award to ascertain his/their financial condition?</label>
                            <input type="text" className="form-control" id=""/>
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormEighteen"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormSeventeen