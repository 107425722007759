import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Scrolltotop from './Scrolltotop';
import Index from './Components/Index';
import BNS from './Components/BNS';
import DAR from './Components/DAR';
import ComplaintForm from './Components/ComplaintForm';
import FirstInformationReport from './Components/FirstInformationReport';
import GeneralDiaryGD from './Components/GeneralDiaryGD';
import ArrestMemo from './Components/ArrestMemo';
import PersonalSearchMemo from './Components/PersonalSearchMemo';
import MedicalExaminationForm from './Components/MedicalExaminationForm';
import ConvictionSlip from './Components/ConvictionSlip';
import AgeMemo from './Components/AgeMemo';
import InnerCaseDiary from './Components/InnerCaseDiary';
import ConvictionSlipTwo from './Components/ConvictionSlipTwo';
import FormOne from './Components/FormOne';
import FormTwo from './Components/FormTwo';
import FormThree from './Components/FormThree';
import FormFour from './Components/FormFour';
import FormFive from './Components/FormFive';
import FormSixA from './Components/FormSixA';
import FormSixB from './Components/FormSixB';
import FormSeven from './Components/FormSeven';
import FormEight from './Components/FormEight';
import FormNine from './Components/FormNine';
import FormTen from './Components/FormTen';
import FormEleven from './Components/FormEleven';
import FormTwelve from './Components/FormTwelve';
import FormThirteen from './Components/FormThirteen';
import FormFourteen from './Components/FormFourteen';
import FormFifteen from './Components/FormFifteen';
import FormSixteen from './Components/FormSixteen';
import FormSeventeen from './Components/FormSeventeen';
import FormEighteen from './Components/FormEighteen';
import FormNineteen from './Components/FormNineteen';

function App() {
  return (
    <HelmetProvider>
    <div className="App"> 
      <Router>
      <Scrolltotop/>
        <Routes>
          <Route path='/' element={<Index/>}/>
          <Route path='/BNS' element={<BNS/>}/>
          <Route path='/DAR' element={<DAR/>}/>
          <Route path='/ComplaintForm' element={<ComplaintForm/>}/> 
          <Route path='/FirstInformationReport' element={<FirstInformationReport/>}/> 
          <Route path='/GeneralDiaryGD' element={<GeneralDiaryGD/>}/> 
          <Route path='/ArrestMemo' element={<ArrestMemo/>}/> 
          <Route path='/PersonalSearchMemo' element={<PersonalSearchMemo/>}/> 
          <Route path='/MedicalExaminationForm' element={<MedicalExaminationForm/>}/> 
          <Route path='/ConvictionSlip' element={<ConvictionSlip/>}/> 
          <Route path='/AgeMemo' element={<AgeMemo/>}/> 
          <Route path='/InnerCaseDiary' element={<InnerCaseDiary/>}/> 
          <Route path='/ConvictionSlipTwo' element={<ConvictionSlipTwo/>}/> 
          <Route path='/FormOne' element={<FormOne/>}/> 
          <Route path='/FormTwo' element={<FormTwo/>}/> 
          <Route path='/FormThree' element={<FormThree/>}/> 
          <Route path='/FormFour' element={<FormFour/>}/> 
          <Route path='/FormFive' element={<FormFive/>}/> 
          <Route path='/FormSixA' element={<FormSixA/>}/> 
          <Route path='/FormSixB' element={<FormSixB/>}/> 
          <Route path='/FormSeven' element={<FormSeven/>}/> 
          <Route path='/FormEight' element={<FormEight/>}/> 
          <Route path='/FormNine' element={<FormNine/>}/> 
          <Route path='/FormTen' element={<FormTen/>}/> 
          <Route path='/FormEleven' element={<FormEleven/>}/> 
          <Route path='/FormTwelve' element={<FormTwelve/>}/> 
          <Route path='/FormThirteen' element={<FormThirteen/>}/> 
          <Route path='/FormFourteen' element={<FormFourteen/>}/> 
          <Route path='/FormFifteen' element={<FormFifteen/>}/> 
          <Route path='/FormSixteen' element={<FormSixteen/>}/> 
          <Route path='/FormSeventeen' element={<FormSeventeen/>}/> 
          <Route path='/FormEighteen' element={<FormEighteen/>}/> 
          <Route path='/FormNineteen' element={<FormNineteen/>}/> 
        </Routes>
      </Router>
    </div>
    </HelmetProvider>
  );
}

export default App;
