import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import { Helmet } from 'react-helmet';

const FormFour = () => {
    
    const fileInputRef0 = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
    const fileInputRef5 = useRef(null);
    const fileInputRef6 = useRef(null);

    const [fileName0, setFileName0] = useState('');
    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');
    const [fileName3, setFileName3] = useState('');
    const [fileName4, setFileName4] = useState('');
    const [fileName5, setFileName5] = useState('');
    const [fileName6, setFileName6] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Owner's Form - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Owner's Form</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Vehicle Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Registration No.</label>
                            <input type="text" className="form-control" id="" placeholder="001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Colour</label>
                            <input type="text" className="form-control" id="" placeholder="Black"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Make</label>
                            <input type="text" className="form-control" id="" placeholder="Make"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Model</label>
                            <input type="text" className="form-control" id="" placeholder="2010"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Year of Manufacture</label>
                            <input type="text" className="form-control" id="" placeholder="2010"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Chassis No.</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Engine No.</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Registering Authority Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Vehicle Type</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Motorized 2-wheeler</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Auto</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Car/Jeep/Taxi</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Cycle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label" for="flexRadioDefault5">Rickshaw</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                    <label className="form-check-label" for="flexRadioDefault6">Bicycle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                                    <label className="form-check-label" for="flexRadioDefault7">Hand Drawn Cart</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                                    <label className="form-check-label" for="flexRadioDefault8">Tempo/Tractor</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" />
                                    <label className="form-check-label" for="flexRadioDefault9">Bus</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" />
                                    <label className="form-check-label" for="flexRadioDefault10">Truck/Lorry</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" />
                                    <label className="form-check-label" for="flexRadioDefault11">Animal Drawn Cart</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault12" />
                                    <label className="form-check-label" for="flexRadioDefault12">Heavy Articulated Vehicle/ Trolley</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault13" />
                                    <label className="form-check-label" for="flexRadioDefault13">Not Known</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault14" />
                                    <label className="form-check-label" for="flexRadioDefault14">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Vehicle Use Type</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault15" />
                                    <label className="form-check-label" for="flexRadioDefault15">Private Vehicle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault16" />
                                    <label className="form-check-label" for="flexRadioDefault16">Commercial Vehicle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault17" />
                                    <label className="form-check-label" for="flexRadioDefault17">Goods & Carriage</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault18" />
                                    <label className="form-check-label" for="flexRadioDefault18">Garbage Truck</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault19" />
                                    <label className="form-check-label" for="flexRadioDefault19">Taxi/Hired Vehicle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault20" />
                                    <label className="form-check-label" for="flexRadioDefault20">Public Service Vehicle</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault21" />
                                    <label className="form-check-label" for="flexRadioDefault21">Educational Institute Bus</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault22" />
                                    <label className="form-check-label" for="flexRadioDefault22">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Owner Details</p>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Name <i>( In case of a company, give name of person in-charge in terms of Section 199 of the MV Act, 1988 )</i></label>
                            <input type="text" className="form-control" id="" placeholder="Pawan Pachauri"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="number" className="form-control" id="" placeholder='+919999999999'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Occupation</label>
                            <input type="text" className="form-control" id="" placeholder="Dimond"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Driver Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father's Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Driving Licence No.</label>
                            <input type="text" className="form-control" id="" placeholder="GJ04 202020"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Validity</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Licensing Authority</label>
                            <input type="text" className="form-control" id="" placeholder="LMV, MCWG"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Insurance Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Insurance Policy No.</label>
                            <input type="text" className="form-control" id="" placeholder="11001100"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Insurance Policy</label>
                            <input type="text" className="form-control" id="" placeholder="10 Year"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="RK Insurance Company"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address of Insurance Company</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Details of previous Insurance Policy</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether the vehicle previously involved in any MACT case? <i>( If yes, give details of FIR and MACT case. )</i></label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>In case of commercial vehicle</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Permit details</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Fitness details</label>
                            <input type="text" className="form-control" id="" placeholder="10 Years"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the owner reported the accident to the Insurance Company</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault23" />
                                    <label className="form-check-label" for="flexRadioDefault23">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault24" />
                                    <label className="form-check-label" for="flexRadioDefault24">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Verification</p>
                        </div>
                        <div className="col-12 input-space">
                            <p className='mb-0'>
                                Verified at
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="location" value={formData.location} onChange={handleChange} />
                                on this
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="day" value={formData.day} onChange={handleChange} />
                                day of
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="month" value={formData.month} onChange={handleChange} />
                                that the contents of the above Form are true to my knowledge and the
                                documents attached are true copies of their originals.
                            </p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">Upload Photograph</label><br />
                            <input type="file" ref={fileInputRef0} onChange={(e) => handleFileChange(e, setFileName0)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef0)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName0 && (
                                <p className="mt-2 file-pre">Selected file: {fileName0}</p>
                            )}
                        </div>
                        <div className="col-md-6 input-space">
                            <div className="entry">
                                <p>Signature of Driver</p>
                            </div>
                            {<SignatureComponent/>}
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Documents to be attached</p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(1) ID/address proof</label><br />
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(2) Registration Certificate</label><br />
                            <input type="file" ref={fileInputRef2} onChange={(e) => handleFileChange(e, setFileName2)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef2)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName2 && (
                                <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(3) Driving Licence of the Driver</label><br />
                            <input type="file" ref={fileInputRef3} onChange={(e) => handleFileChange(e, setFileName3)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef3)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName3 && (
                                <p className="mt-2 file-pre">Selected file: {fileName3}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(4) Insurance Policy</label><br />
                            <input type="file" ref={fileInputRef4} onChange={(e) => handleFileChange(e, setFileName4)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef4)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName4 && (
                                <p className="mt-2 file-pre">Selected file: {fileName4}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">(5) Permit</label><br />
                            <input type="file" ref={fileInputRef5} onChange={(e) => handleFileChange(e, setFileName5)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef5)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName5 && (
                                <p className="mt-2 file-pre">Selected file: {fileName5}</p>
                            )}
                        </div>
                        <div className="col-12 input-space mb-0">
                            <label className="form-label">(6) Fitness</label><br />
                            <input type="file" ref={fileInputRef6} onChange={(e) => handleFileChange(e, setFileName6)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef6)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName6 && (
                                <p className="mt-2 file-pre">Selected file: {fileName6}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormFive"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormFour