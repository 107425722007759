import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormTen = () => {
    
    const fileInputRef0 = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
    const fileInputRef5 = useRef(null);
    const fileInputRef6 = useRef(null);

    const [fileName0, setFileName0] = useState('');
    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');
    const [fileName3, setFileName3] = useState('');
    const [fileName4, setFileName4] = useState('');
    const [fileName5, setFileName5] = useState('');
    const [fileName6, setFileName6] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Verification Report - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Verification Report</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle Registration No.</label>
                            <input type="text" className="form-control" id="" placeholder="101010101010"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Validity Period</label>
                            <input type="text" className="form-control" id="" placeholder="10 Years"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Engine Number</label>
                            <input type="text" className="form-control" id="" placeholder="101010101010"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Chassis Number</label>
                            <input type="text" className="form-control" id="" placeholder="101010101010"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Category of Vehicle</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">LMV/LMV-T/HMV/MGV </label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Private or Commercial</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Vehicle Make & Model</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle make</label>
                            <input type="text" className="form-control" id="" placeholder="Vehicle make"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Model Name</label>
                            <input type="text" className="form-control" id="" placeholder="Hiro Honda"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Owner Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rao"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Details of Insurer</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Details of Insurer'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Details of Permit</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Details of Permit'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Permit No.</label>
                            <input type="number" className="form-control" id="" placeholder="0010102"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Validity</label>
                            <input type="date" className="form-control" id="" value="2034-10-20"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Fitness Certificate</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Fitness Certificate No.</label>
                            <input type="number" className="form-control" id="" placeholder="1"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Validity</label>
                            <input type="date" className="form-control" id="" value="2034-10-20"/>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">In case record not available, state reasons</label>
                            <TextEditor />
                        </div>
                        <div className="entry">
                            <p className='mb-32'>S.H.O./I.O</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.I.S. No.</label>
                            <input type="number" className="form-control" id="" placeholder="0001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Phone No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.S.</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space mb-0">
                            <label className="form-label">Documents to be attached</label><br />
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} multiple/>
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormEleven"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormTen