import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import { Helmet } from 'react-helmet';

const FormTwelve = () => {
    const [hasPermanentDisability, setHasPermanentDisability] = useState(null);
    const [hasAccidentHistory, setHasAccidentHistory] = useState(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);

    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const handleAccidentHistoryChange = (e) => {
        setHasAccidentHistory(e.target.value);
    };

    const handleDisabilityRadioChange = (e) => {
        setHasPermanentDisability(e.target.value);
    };

    const [representatives, setRepresentatives] = useState([
        { name: '', relationship: '', age: '', gender: '' },
    ]);
    const [representatives2, setRepresentatives2] = useState([
        { name: '', relationship: '', age: '', gender: '' },
    ]);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newRepresentatives = [...representatives];

        if (name.startsWith('gender')) {
            newRepresentatives[index].gender = value;
        } else {
            newRepresentatives[index][name] = value;
        }

        setRepresentatives(newRepresentatives);
    };

    const handleChange2 = (index, event) => {
        const { name, value } = event.target;
        const newRepresentatives = [...representatives];

        if (name.startsWith('gender')) {
            newRepresentatives[index].gender = value;
        } else {
            newRepresentatives[index][name] = value;
        }

        setRepresentatives2(newRepresentatives);
    };


    const addRepresentative = () => {
        setRepresentatives([...representatives, { name: '', relationship: '', age: '', gender: '' }]);
    };
    const addRepresentative2 = () => {
        setRepresentatives2([...representatives, { name: '', relationship: '', age: '', gender: '' }]);
    };

    const removeRepresentative = (index) => {
        const newRepresentatives = representatives.filter((_, i) => i !== index);
        setRepresentatives(newRepresentatives);
    };
    const removeRepresentative2 = (index) => {
        const newRepresentatives = representatives.filter((_, i) => i !== index);
        setRepresentatives2(newRepresentatives);
    };

    return (
        <div>
            <Helmet>
                <title>Victim Impact Report - FIR Management System </title>
            </Helmet>
            <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Victim Impact Report</h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">FIR No</label>
                                <input type="text" className="form-control" id="" placeholder="84721" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date</label>
                                <input type="date" className="form-control" id="" value="2024-09-21" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Under Section </label>
                                <select id="inputState" className="form-select">
                                    <option selected>154 cr pc</option>
                                    <option>154 cr pc</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Police Station</label>
                                <select id="inputState" className="form-select">
                                    <option selected>Sadar, Bazar , Delehi</option>
                                    <option>Sadar, Bazar , Delehi</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date of offence</label>
                                <input type="date" className="form-control" id="" value="2024-09-21" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Time of offence</label>
                                <input type="time" className="form-control" id="" value="10:10" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Place of offence</label>
                                <input type="text" className="form-control" id="" placeholder="Gandhinagar" />
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Nature of injury/loss suffered by the victim(s) </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Nature of injury" id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">Simple injuries</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Nature of injury" id="flexRadioDefault2" />
                                        <label className="form-check-label" for="flexRadioDefault2">Grievous injuries</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Nature of injury" id="flexRadioDefault3" />
                                        <label className="form-check-label" for="flexRadioDefault3">Death</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Nature of injury" id="flexRadioDefault4" />
                                        <label className="form-check-label" for="flexRadioDefault4">Emotional harm</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Nature of injury" id="flexRadioDefault5" />
                                        <label className="form-check-label" for="flexRadioDefault5">Damage/loss of the property</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Nature of injury" id="flexRadioDefault6" />
                                        <label className="form-check-label" for="flexRadioDefault6">Any other loss/injury</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="drivingReason" className="form-label">Brief description of offence(s) in which the accused has been convicted
                                </label>
                                <textarea className="form-control" id="drivingReason" rows="3" placeholder='Description...'></textarea>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the victim </label>
                                <input type="text" className="form-control" id="" placeholder="Rajesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Father’s /Spouse’s name </label>
                                <input type="text" className="form-control" id="" placeholder="Mukesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age </label>
                                <input type="number" className="form-control" id="" placeholder="Enter Age" />
                            </div>
                            <div className="col-md-6 input-space-check">
                                <label for="inputEmail4" className="form-label">Gender</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender" id="flexRadioDefault7" />
                                        <label className="form-check-label" for="flexRadioDefault7">Male</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender" id="flexRadioDefault8" />
                                        <label className="form-check-label" for="flexRadioDefault8">Female</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Marital status </label>
                                <input type="text" className="form-control" id="" placeholder="Enter Status" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Permanent Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Present Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Contact information:</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Mobile</label>
                                <input type="number" className="form-control" id="" placeholder="Enter Mobile number" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Email ID </label>
                                <input type="email" className="form-control" id="" placeholder="Enter Email ID" />
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Death Case</h1>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Father’s/Spouse’s name </label>
                                <input type="text" className="form-control" id="" placeholder="Mukesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space-check">
                                <label for="inputEmail4" className="form-label">Gender of the deceased </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender of the deceased1" id="flexRadioDefault9" />
                                        <label className="form-check-label" for="flexRadioDefault9">Male</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender of the deceased1" id="flexRadioDefault10" />
                                        <label className="form-check-label" for="flexRadioDefault10">Female</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Marital status of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter Status" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age of the deceased </label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Income of the deceased </label>
                                <input type="number" className="form-control" id="" placeholder="Enter monthly income" />
                            </div>
                            <div className="col-12 input-space">
                                <p>Name, age and relationship of legal representatives of deceased:</p>
                                {representatives.map((rep, index) => (
                                    <div key={index} className="mb-3 mt-4 d-flex flex-column input-space">
                                        <div className='row'>
                                        <div className="col-md-3 input-space mb-3">
                                                <label htmlFor="" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={rep.name}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control mb-2"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label htmlFor="" className="form-label">Relationship</label>
                                                <input
                                                    type="text"
                                                    name="relationship"
                                                    placeholder="Relationship"
                                                    value={rep.relationship}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control mb-2"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label htmlFor="" className="form-label">Age</label>
                                                <input
                                                    type="number"
                                                    name="age"
                                                    placeholder="Age"
                                                    value={rep.age}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control mb-2"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label className="form-label">Gender</label>
                                                <div className='d-flex flex-wrap'>
                                                    <div className="form-check me-5 mb-3">
                                                        <input
                                                            className="form-check-input p-0"
                                                            type="radio"
                                                            name={`gender-${index}`}
                                                            id={`male-${index}`}
                                                            value="male"
                                                            checked={rep.gender === 'male'}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`male-${index}`}>
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="form-check me-5 mb-3">
                                                        <input
                                                            className="form-check-input p-0"
                                                            type="radio"
                                                            name={`gender-${index}`}
                                                            id={`female-${index}`}
                                                            value="female"
                                                            checked={rep.gender === 'female'}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`female-${index}`}>
                                                            Female
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 input-space d-flex justify-content-start align-items-center mb-0">                                                
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeRepresentative(index)}><i class="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={addRepresentative}>
                                    Add Another Representative
                                </button>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of losses suffered</p>
                            </div>
                            <div className="col-12 input-space">
                                <div className="table-responsive">

                                
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60%" }}>Pecuniary Losses:</th>
                                            <th scope="col" style={{ width: "40%" }}>Amount in Rs: </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Income of the deceased (A)  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Add-Future Prospects (B) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Less-Personal expenses of the deceased (C) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Monthly loss of dependency [(A+B) – C = D] </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Annual loss of dependency (D x 12) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Multiplier (E) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total loss of dependency (E x 12 x D = F) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Medical Expenses  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Funeral Expenses </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any other pecuniary loss/damage </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of consortium </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of love and affection </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of estate </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Emotional	harm/trauma,	mental	and physical shock etc. </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Post-traumatic stress disorder (anxiety, depression, hostility, insomnia, self- destructive behaviour, nightmares, agitation,
                                                social isolation, etc.) panic disorder or phobia(a) which got triggered by the incident/death of the deceased victim. </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any other non-pecuniary loss/damage </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total loss suffered </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Injury Case</h1>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the injured</label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Father’s /Spouse’s name </label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age of the injured </label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space-check">
                                <label for="inputEmail4" className="form-label">Gender of the deceased </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender of the deceased2" id="flexRadioDefault11" />
                                        <label className="form-check-label" for="flexRadioDefault11">Male</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender of the deceased2" id="flexRadioDefault12" />
                                        <label className="form-check-label" for="flexRadioDefault12">Female</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Marital status of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter Status" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation of the injured </label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Income of the injured </label>
                                <input type="number" className="form-control" id="" placeholder="Enter monthly income" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Nature and description of injury </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Medical treatment taken by the injured </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Name of hospital and period of hospitalization </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Details of	surgeries, if undergone </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether any permanent disability?
                                    <i>(If yes, give details)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefaultDisability"
                                            id="flexRadioDefaultDisabilityYes"
                                            value="yes"
                                            onChange={handleDisabilityRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultDisabilityYes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefaultDisability"
                                            id="flexRadioDefaultDisabilityNo"
                                            value="no"
                                            onChange={handleDisabilityRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultDisabilityNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {hasPermanentDisability === 'yes' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="disabilityDetails" className="form-label">Give details</label>
                                    <textarea
                                        className="form-control"
                                        id="disabilityDetails"
                                        rows="3"
                                        placeholder="Description of permanent disability..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Whether the injured got reimbursement of medical expenses </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <p>Details of family/dependents of the injured: </p>
                                {representatives2.map((rep, index) => (
                                    <div key={index} className="mb-3 mt-4 d-flex flex-column input-space">
                                        <div className='row'>
                                        <div className="col-md-3 input-space mb-3">
                                                <label htmlFor="" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={rep.name}
                                                    onChange={(event) => handleChange2(index, event)}
                                                    className="form-control mb-2"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label htmlFor="" className="form-label">Relationship</label>
                                                <input
                                                    type="text"
                                                    name="relationship"
                                                    placeholder="Relationship"
                                                    value={rep.relationship}
                                                    onChange={(event) => handleChange2(index, event)}
                                                    className="form-control mb-2"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label htmlFor="" className="form-label">Age</label>
                                                <input
                                                    type="number"
                                                    name="age"
                                                    placeholder="Age"
                                                    value={rep.age}
                                                    onChange={(event) => handleChange2(index, event)}
                                                    className="form-control mb-2"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label className="form-label">Gender</label>
                                                <div className='d-flex flex-wrap'>
                                                    <div className="form-check me-5 mb-3">
                                                        <input
                                                            className="form-check-input p-0"
                                                            type="radio"
                                                            name={`gender-${index}`}
                                                            id={`male2-${index}`}
                                                            value="male"
                                                            checked={rep.gender === 'male'}
                                                            onChange={(event) => handleChange2(index, event)} />
                                                        <label className="form-check-label" htmlFor={`male2-${index}`}>
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="form-check me-5 mb-3">
                                                        <input
                                                            className="form-check-input p-0"
                                                            type="radio"
                                                            name={`gender-${index}`}
                                                            id={`female2-${index}`}
                                                            value="female"
                                                            checked={rep.gender === 'female'}
                                                            onChange={(event) => handleChange2(index, event)} />
                                                        <label className="form-check-label" htmlFor={`female2-${index}`}>
                                                            Female
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 input-space d-flex justify-content-start align-items-center mb-0">                                                
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeRepresentative2(index)}><i class="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={addRepresentative2}>
                                    Add Another Representative
                                </button>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of losses suffered </p>
                            </div>
                            <div className="col-12 input-space">
                                <div className="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60%" }}>Pecuniary Losses: </th>
                                            <th scope="col" style={{ width: "40%" }}>Amount in Rs: </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Expenditure incurred on  treatment, conveyance, special diet, attendant etc. </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>If treatment is still continuing, give the estimate of expenditure likely to be incurred on future treatment </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of income </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any other loss which may require any special treatment or aid to the injured for the rest of his life </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of disability assessed and nature of disability as permanent or temporary  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Percentage	of	loss	of	earning capacity in relation to disability </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss  of  future  Income  -  (Income x % Earning Capacity x Multiplier) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any other pecuniary loss/damage </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total loss </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of losses suffered </p>
                            </div>
                            <div className="col-12 input-space">
                            <div className="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60%" }}>Non-Pecuniary Losses:</th>
                                            <th scope="col" style={{ width: "40%" }}>Losses: </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Pain and suffering </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of amenities of life, inconvenience, hardships, disappointment, frustration, mental stress, dejectment and
                                                unhappiness in future life etc. </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Post-traumatic stress disorder (anxiety, depression, hostility, insomnia,	self-destructive behaviour, nightmares,
                                                agitation, social isolation, etc.) panic disorder or phobia(a) which got triggered by the incident. </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Emotional harm/trauma, mental and physical shock etc. </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Disfiguration </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of marriage prospects </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of Reputation </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any	other non-pecuniary loss/damage </td>
                                            <td><input className="form-control" id="" placeholder="Enter loss" type="text"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total loss suffered </td>
                                            <td><input className="form-control" id="" placeholder="Describe" type="text"></input></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Damage/Loss to the property</h1>
                                </div>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Description	of	the	property damaged/lost </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">The value of loss suffered </label>
                                <input type="number" className="form-control" id="" placeholder="Enter Amount" />
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Conduct of the accused </h1>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the accused fled from the Spot  <i>( If so, when he/ she appeared before Police/
                                    Court or arrested? )</i></label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused1" id="flexRadioDefault13" />
                                        <label className="form-check-label" for="flexRadioDefault13">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused1" id="flexRadioDefault14" />
                                        <label className="form-check-label" for="flexRadioDefault14">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the Accused reported the accident to the Police/ family of the victim </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused2" id="flexRadioDefault15" />
                                        <label className="form-check-label" for="flexRadioDefault15">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused2" id="flexRadioDefault16" />
                                        <label className="form-check-label" for="flexRadioDefault16">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="Conduct of the accused3" id="flexRadioDefault17" />
                                    <label className="form-check-label" for="flexRadioDefault17">Whether the Accused provided any assistance to the victim? </label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="Conduct of the accused3" id="flexRadioDefault18" />
                                    <label className="form-check-label" for="flexRadioDefault18">Whether the Accused took the victim to the hospital? </label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="Conduct of the accused3" id="flexRadioDefault19" />
                                    <label className="form-check-label" for="flexRadioDefault19">Whether the Accused visited the victim at the hospital? </label>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the Accused remained at the spot till police arrived </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused4" id="flexRadioDefault20" />
                                        <label className="form-check-label" for="flexRadioDefault20">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused4" id="flexRadioDefault21" />
                                        <label className="form-check-label" for="flexRadioDefault21">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the Accused cooperated in the investigation </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused5" id="flexRadioDefault22" />
                                        <label className="form-check-label" for="flexRadioDefault22">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused5" id="flexRadioDefault23" />
                                        <label className="form-check-label" for="flexRadioDefault23">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the Accused removed his/ her vehicle from the spot before police arrived </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused6" id="flexRadioDefault24" />
                                        <label className="form-check-label" for="flexRadioDefault24">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused6" id="flexRadioDefault25" />
                                        <label className="form-check-label" for="flexRadioDefault25">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether	the	Accused	paid compensation/ medical expenses to victim/ his family </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused7" id="flexRadioDefault26" />
                                        <label className="form-check-label" for="flexRadioDefault26">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused7" id="flexRadioDefault27" />
                                        <label className="form-check-label" for="flexRadioDefault27">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the Accused has previous convictions </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused8" id="flexRadioDefault28" />
                                        <label className="form-check-label" for="flexRadioDefault28">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused8" id="flexRadioDefault29" />
                                        <label className="form-check-label" for="flexRadioDefault29">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether   the   Accused   is/   was a close relative or friend of the victim  </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused9" id="flexRadioDefault30" />
                                        <label className="form-check-label" for="flexRadioDefault30">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused9" id="flexRadioDefault31" />
                                        <label className="form-check-label" for="flexRadioDefault31">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Age of the Accused </label>
                                <input type="number" className="form-control" id="" placeholder="Enter Age" />
                            </div>
                            <div className="col-md-6 input-space-check">
                                <label for="inputEmail4" className="form-label">Gender of the Accused </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender of the deceased3" id="flexRadioDefault00" />
                                        <label className="form-check-label" for="flexRadioDefault00">Male</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Gender of the deceased3" id="flexRadioDefault01" />
                                        <label className="form-check-label" for="flexRadioDefault01">Female</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether accused suffered injuries during the accident  </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused10" id="flexRadioDefault32" />
                                        <label className="form-check-label" for="flexRadioDefault32">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused10" id="flexRadioDefault33" />
                                        <label className="form-check-label" for="flexRadioDefault33">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Whether the Accused discharged the duties under Sections 132 and 134 of the MV Act, 1988? If
                                    no, whether the Accused has been prosecuted under Section 187 of MV Act </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused11" id="flexRadioDefault34" />
                                        <label className="form-check-label" for="flexRadioDefault34">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused11" id="flexRadioDefault35" />
                                        <label className="form-check-label" for="flexRadioDefault35">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether the Driver has been previously involved in a motor accident case?
                                    <i>(If Yes, provide following details: FIR Number and Police Station)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioAccidentHistory"
                                            id="flexRadioAccidentHistoryYes"
                                            value="yes"
                                            onChange={handleAccidentHistoryChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioAccidentHistoryYes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioAccidentHistory"
                                            id="flexRadioAccidentHistoryNo"
                                            value="no"
                                            onChange={handleAccidentHistoryChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioAccidentHistoryNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {hasAccidentHistory === 'yes' && (
                                <div>
                                    <div className="col-12 input-space">
                                        <label htmlFor="firNumber" className="form-label">FIR Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firNumber"
                                            placeholder="Enter FIR Number"
                                        />
                                    </div>
                                    <div className="col-12 input-space">
                                        <label htmlFor="policeStation" className="form-label">Police Station</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="policeStation"
                                            placeholder="Enter Police Station Name"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">In case the driver fled from the spot, did the owner comply with the provisions of Section
                                    133 of MV Act </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused13" id="flexRadioDefault38" />
                                        <label className="form-check-label" for="flexRadioDefault38">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused13" id="flexRadioDefault39" />
                                        <label className="form-check-label" for="flexRadioDefault39">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Any other information regarding the conduct of the Accused </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused14" id="flexRadioDefault40" />
                                        <label className="form-check-label" for="flexRadioDefault40">Yes</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="Conduct of the accused14" id="flexRadioDefault41" />
                                        <label className="form-check-label" for="flexRadioDefault41">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Apparent contributing circumstances </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="row d-flex justify-content-start">
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault1" id="circumstances1" />
                                                <label className="form-check-label" for="circumstances1">
                                                    Driving	without	valid	driving license
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault2" id="circumstances2" />
                                                <label className="form-check-label" for="circumstances2">
                                                    Driving while disqualified
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault3" id="circumstances3" />
                                                <label className="form-check-label" for="circumstances3">
                                                    Learner driving without supervision
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault4" id="circumstances4" />
                                                <label className="form-check-label" for="circumstances4">
                                                    Vehicle not insured
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault5" id="circumstances5" />
                                                <label className="form-check-label" for="circumstances5">
                                                    Driving a stolen vehicle
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault6" id="circumstances6" />
                                                <label className="form-check-label" for="circumstances6">
                                                    Vehicle	taken	out	without	the consent of the owner
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault7" id="circumstances7" />
                                                <label className="form-check-label" for="circumstances7">
                                                    Driving dangerously or at excessive speed
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault8" id="circumstances8" />
                                                <label className="form-check-label" for="circumstances8">
                                                    Dangerously	loaded	vehicle/Overloaded
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault9" id="circumstances9" />
                                                <label className="form-check-label" for="circumstances9">
                                                    Parking on the wrong side of the road
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault10" id="circumstances10" />
                                                <label className="form-check-label" for="circumstances10">
                                                    Improper	parking/	Parking	on wrong side of road
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault11" id="circumstances11" />
                                                <label className="form-check-label" for="circumstances11">
                                                    Non-observance of traffic rules
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault12" id="circumstances12" />
                                                <label className="form-check-label" for="circumstances12">
                                                    Poorly maintained vehicle
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault13" id="circumstances13" />
                                                <label className="form-check-label" for="circumstances13">
                                                    Fake/forged driving license
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault14" id="circumstances14" />
                                                <label className="form-check-label" for="circumstances14">
                                                    History of convulsions/ seizures
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault15" id="circumstances15" />
                                                <label className="form-check-label" for="circumstances15">
                                                    Fatigued/ Sleepy
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault16" id="circumstances16" />
                                                <label className="form-check-label" for="circumstances16">
                                                    Guilty of violation of traffic rules in the past
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault17" id="circumstances17" />
                                                <label className="form-check-label" for="circumstances17">
                                                    Previous convictions
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault18" id="circumstances18" />
                                                <label className="form-check-label" for="circumstances18">
                                                    Suffering from medical condition that impairs driving
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault19" id="circumstances19" />
                                                <label className="form-check-label" for="circumstances19">
                                                    Using mobile phone while driving (Handheld)
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault20" id="circumstances20" />
                                                <label className="form-check-label" for="circumstances20">
                                                    More than one injured/ dead
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault21" id="circumstances21" />
                                                <label className="form-check-label" for="circumstances21">
                                                    Under the influence of alcohol or drugs
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Aggressive Driving </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="row d-flex justify-content-start">
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault22" id="driving22" />
                                                <label className="form-check-label" for="driving22">
                                                    Jumping Red Light
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault23" id="driving23" />
                                                <label className="form-check-label" for="driving23">
                                                    Abrupt braking
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault24" id="driving24" />
                                                <label className="form-check-label" for="driving24">
                                                    Neglect to keep to the left of road
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault25" id="driving25" />
                                                <label className="form-check-label" for="driving25">
                                                    Criss Cross Driving
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault26" id="driving26" />
                                                <label className="form-check-label" for="driving26">
                                                    Driving on the wrong side
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault27" id="driving27" />
                                                <label className="form-check-label" for="driving27">
                                                    Driving close to vehicle in front
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault28" id="driving28" />
                                                <label className="form-check-label" for="driving28">
                                                    Inappropriate attempts to overtake
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault29" id="driving29" />
                                                <label className="form-check-label" for="driving29">
                                                    Cutting in after overtaking
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault30" id="driving30" />
                                                <label className="form-check-label" for="driving30">
                                                    Exceeding Speed Limit
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault31" id="driving31" />
                                                <label className="form-check-label" for="driving31">
                                                    Racing/ Competitive Driving
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault32" id="driving32" />
                                                <label className="form-check-label" for="driving32">
                                                    Disregarding any warnings
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault33" id="driving33" />
                                                <label className="form-check-label" for="driving33">
                                                    Overtaking where prohibited
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault34" id="driving34" />
                                                <label className="form-check-label" for="driving34">
                                                    Driving with loud music
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault35" id="driving35" />
                                                <label className="form-check-label" for="driving35">
                                                    Improper reversing
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault36" id="driving36" />
                                                <label className="form-check-label" for="driving36">
                                                    Improper passing
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault37" id="driving37" />
                                                <label className="form-check-label" for="driving37">
                                                    Improper turning
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault38" id="driving38" />
                                                <label className="form-check-label" for="driving38">
                                                    Turning without indication
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault39" id="driving39" />
                                                <label className="form-check-label" for="driving39">
                                                    Driving in no-entry zone
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault40" id="driving40" />
                                                <label className="form-check-label" for="driving40">
                                                    Not slowing at junctions/ crossings
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault41" id="driving41" />
                                                <label className="form-check-label" for="driving41">
                                                    Turning with indication
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault42" id="driving42" />
                                                <label className="form-check-label" for="driving42">
                                                    Not respecting stop sign
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault43" id="driving43" />
                                                <label className="form-check-label" for="driving43">
                                                    Not respecting right of way to pedestrians
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Irresponsible Behaviour </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="row d-flex justify-content-start">
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault44" id="circumstances44" />
                                                <label className="form-check-label" for="circumstances44">
                                                    Failing to stop after accident
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault45" id="circumstances45" />
                                                <label className="form-check-label" for="circumstances45">
                                                    Ran away from the spot after leaving the vehicle
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault46" id="circumstances46" />
                                                <label className="form-check-label" for="circumstances46">
                                                    Destruction or attempt to destroy the evidence
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault47" id="circumstances47" />
                                                <label className="form-check-label" for="circumstances47">
                                                    Falsely claiming that one of the victims was responsible for the accident
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault48" id="circumstances48" />
                                                <label className="form-check-label" for="circumstances48">
                                                    Trying to throw the victim off the bonnet of the vehicle by swerving in order to escape
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault49" id="circumstances49" />
                                                <label className="form-check-label" for="circumstances49">
                                                    Causing death/injury in the course of dangerous driving post commission of crime or <br />chased by police in an attempt to
                                                    avoid detection or apprehension
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault50" id="circumstances50" />
                                                <label className="form-check-label" for="circumstances50">
                                                    Offence	committed	while	the offender was on bail
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault51" id="circumstances51" />
                                                <label className="form-check-label" for="circumstances51">
                                                    Took any false defence
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault52" id="circumstances52" />
                                                <label className="form-check-label" for="circumstances52">
                                                    Misled the investigation
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexCheckDefault53" id="circumstances53" />
                                                <label className="form-check-label" for="circumstances53">
                                                    Post-accident road rage behaviour
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Paying capacity of the accused </p>
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="drivingReason" className="form-label">The accused has submitted the affidavit of his assets and income in the format
                                    Annexure-A. The particulars given by the accused in his affidavit have been verified through SDM/Police/Prosecution and after
                                    considering the same, paying capacity of the accused is assessed as under: </label>
                                <textarea className="form-control" id="drivingReason" rows="3" placeholder='Description...'></textarea>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Recommendations of Delhi State Legal Services Authority </p>
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="drivingReason" className="form-label">After taking into consideration the gravity of the offense, severity of mental/physical
                                    harm/injuries suffered by the victim(s); losses suffered by the victim(s) and the paying capacity of the accused. The recommendations of
                                    the Committee are as under:- </label>
                                <textarea className="form-control" id="drivingReason" rows="3" placeholder='Description...'></textarea>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Delhi Date:</label>
                                <input type="date" className="form-control" id="" value="2024-09-21" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label className="form-label">Member Secretary Delhi State Legal Services Authority</label>
                                {<SignatureComponent />}
                            </div>
                            <div className="entry">
                                <p className='mb-3'>Documents considered and attached to the report</p>
                            </div>
                            <div className="entry">
                                <p className='mb-3'>In Death Cases:</p>
                            </div>
                            <div className="col-12 input-space mb-0">
                                <label className="form-label"><i>1. Death certificate </i></label>
                                <br />
                                <label className="form-label"><i>2. Proof of age of the deceased which may be in form of <br />
                                    a) Birth Certificate; <br />
                                    b) School Certificate; <br />
                                    c)Certificate from Gram Panchayat (in case of illiterate);<br />
                                    d) Aadhar Card <br />
                                </i></label><br />
                                <label className="form-label"><i>3. Proof of Occupation and Income of the deceased which may be in form of <br />
                                    a) Pay slip/salary certificate (salaried employee); <br />
                                    b) Bank statements of the last six months; <br />
                                    c) Income tax Return; Balance Sheet <br />
                                </i></label><br />
                                <label className="form-label"><i>4. Proof of the legal representatives of the deceased (Names, Age, Address, Phone Number & Relationship)
                                    Proof of the legal representatives of the deceased (Names, Age, Address, Phone Number & Relationship) </i></label>
                                <br />
                                <label className="form-label"><i>5. Treatment record, medical bills and other expenditure  </i></label>
                                <br />
                                <label className="form-label"><i>6. Bank Account no. of the legal representatives of the deceased with name and address of the bank  </i>
                                </label>
                                <br />
                                <label className="form-label"><i>7. Any other document found relevant  </i>
                                </label>
                                <br />
                                 <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} multiple />
                                <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                    <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                </button>
                                {fileName1 && (
                                    <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                                )}
                            </div>
                            <div className="entry">
                                <p className='mb-3 mt-3'>In Injury Cases: </p>
                            </div>
                            <div className="col-12 input-space mb-0">
                                <label className="form-label"><i>1. Multi angle photographs of the injured  </i></label>
                                <br />
                                <label className="form-label"><i>2. Proof of age of the deceased which may be in form of  <br />
                                    a) Birth Certificate;  <br />
                                    b) School Certificate; <br />
                                    c) Certificate from Gram Panchayat (in case of illiterate); <br />
                                    d) Aadhar Card <br />
                                </i></label><br />
                                <label className="form-label"><i>3. Proof of Occupation and Income of the deceased which may be in form of  <br />
                                    a) Pay slip/salary certificate (salaried employee); <br />
                                    b) Bank statements of the last six months;  <br />
                                    c) Income tax Return; Balance Sheet <br />
                                </i></label><br />
                                <label className="form-label"><i>4. Treatment record, medical bills and other expenditure.  </i></label>
                                <br />
                                <label className="form-label"><i>5. Disability certificate (if available)  </i></label>
                                <br />
                                <label className="form-label"><i>6. Proof of absence from work where loss of income on account of injury is being claimed, which may be in 
                                    the form of <br />
                                    a) Certificate from the employer; <br />
                                    b) Extracts from the attendance register.  <br />
                                </i>
                                </label>
                                <br />
                                <label className="form-label"><i>7. Proof of reimbursement of medical expenses by employer or under a Mediclaim policy, if taken   </i>
                                </label>
                                <br />
                                <label className="form-label"><i>8. Any other document found relevant   </i>
                                </label>
                                <br />
                                <input type="file" ref={fileInputRef2} onChange={(e) => handleFileChange(e, setFileName2)} style={{ display: 'none' }} multiple />
                                <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef2)} >
                                    <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                </button>
                                {fileName2 && (
                                    <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                                )}
                            </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/FormThirteen"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormTwelve