import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormSeven = () => {
    const [isLicenseVerified, setIsLicenseVerified] = useState(null);
    const [isDrivingLicenseSuspended, setIsDrivingLicenseSuspended] = useState(null);
    const [isDriverInjured, setIsDriverInjured] = useState(null);
    const [isUnderInfluence, setIsUnderInfluence] = useState(null);
    const [isCarryingMobile, setIsCarryingMobile] = useState(null);
    const [isPreviouslyInvolved, setIsPreviouslyInvolved] = useState(null);
    const [isPermitVerified, setIsPermitVerified] = useState(null);
    const [isAccidentReported, setIsAccidentReported] = useState(null);
    const [isDriverProduced, setIsDriverProduced] = useState(null);
    const [fileName1, setFileName1] = useState('');
    const fileInputRef1 = useRef(null);
    const [reportDate, setReportDate] = useState('');

    const [representatives, setRepresentatives] = useState([
        { name: '', relationship: '', age: '' },
    ]);

    const handleLicenseRadioChange = (e) => {
        setIsLicenseVerified(e.target.value);
    };

    const handleDrivingLicenseRadioChange = (e) => {
        setIsDrivingLicenseSuspended(e.target.value);
    };

    const handleDriverInjuredRadioChange = (e) => {
        setIsDriverInjured(e.target.value);
    };

    const handleUnderInfluenceRadioChange = (e) => {
        setIsUnderInfluence(e.target.value);
    };

    const handleCarryingMobileRadioChange = (e) => {
        setIsCarryingMobile(e.target.value);
    };

    const handlePreviouslyInvolvedRadioChange = (e) => {
        setIsPreviouslyInvolved(e.target.value);
    };

    const handlePermitRadioChange = (e) => {
        setIsPermitVerified(e.target.value);
    };

    const handleAccidentReportedRadioChange = (e) => {
        setIsAccidentReported(e.target.value);
    };

    const handleDriverProducedRadioChange = (e) => {
        setIsDriverProduced(e.target.value);
    };

    const handleFileChange = (e, setFileName) => {
        setFileName(e.target.files[0]?.name || '');
    };

    const handleUploadClick = (fileInputRef) => {
        fileInputRef.current.click();
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newRepresentatives = [...representatives];
        newRepresentatives[index][name] = value;
        setRepresentatives(newRepresentatives);
    };

    const addRepresentative = () => {
        setRepresentatives([...representatives, { name: '', relationship: '', age: '' }]);
    };

    const removeRepresentative = (index) => {
        const newRepresentatives = representatives.filter((_, i) => i !== index);
        setRepresentatives(newRepresentatives);
    };


    return (
        <div>
            <Helmet>
                <title>Detailed Accident Report (DAR) - FIR Management System</title>
            </Helmet>
            <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Detailed Accident Report (DAR) </h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">FIR No</label>
                                <input type="text" className="form-control" id="" placeholder="84721" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date</label>
                                <input type="date" className="form-control" id="" value="2024-09-21" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Under Section </label>
                                <select id="inputState" className="form-select">
                                    <option selected>154 cr pc</option>
                                    <option>154 cr pc</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Police Station</label>
                                <select id="inputState" className="form-select">
                                    <option selected>Sadar, Bazar , Delehi</option>
                                    <option>Sadar, Bazar , Delehi</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date of Accident</label>
                                <input type="date" className="form-control" id="" value="2024-09-21" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Time of Accident</label>
                                <input type="time" className="form-control" id="" value="10:10" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Place of Accident</label>
                                <input type="text" className="form-control" id="" placeholder="Gandhinagar" />
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Nature of case</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">Simple Injury </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label" for="flexRadioDefault2">Grievous Injury</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                        <label className="form-check-label" for="flexRadioDefault3">Fatal</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                        <label className="form-check-label" for="flexRadioDefault4">Damage/loss of the property</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                        <label className="form-check-label" for="flexRadioDefault5">Any other loss/injury</label>
                                    </div>
                                </div>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Offending Vehicle</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Registration No.</label>
                                <input type="text" className="form-control" id="" placeholder="001" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Vehicle Make</label>
                                <input type="text" className="form-control" id="" placeholder="Vehicle Make" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Vehicle Model</label>
                                <input type="text" className="form-control" id="" placeholder="2010" />
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Vehicle Type</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault6" />
                                        <label className="form-check-label" for="flexRadioDefault6">Motorized 2-wheeler</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault7" />
                                        <label className="form-check-label" for="flexRadioDefault7">Auto</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault8" />
                                        <label className="form-check-label" for="flexRadioDefault8">Car/Jeep/Taxi</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault9" />
                                        <label className="form-check-label" for="flexRadioDefault9">Cycle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault10" />
                                        <label className="form-check-label" for="flexRadioDefault10">Rickshaw</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault11" />
                                        <label className="form-check-label" for="flexRadioDefault11">Bicycle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault12" />
                                        <label className="form-check-label" for="flexRadioDefault12">Hand Drawn Cart</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault13" />
                                        <label className="form-check-label" for="flexRadioDefault13">Tempo/Tractor</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault14" />
                                        <label className="form-check-label" for="flexRadioDefault14">Bus</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault15" />
                                        <label className="form-check-label" for="flexRadioDefault15">Truck/Lorry</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault16" />
                                        <label className="form-check-label" for="flexRadioDefault16">Animal Drawn Cart</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault17" />
                                        <label className="form-check-label" for="flexRadioDefault17">Heavy Articulated Vehicle/ Trolley</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault18" />
                                        <label className="form-check-label" for="flexRadioDefault18">Not Known</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault19" />
                                        <label className="form-check-label" for="flexRadioDefault19">Others (Specify)</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail1" className="form-label">Vehicle Use Type</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault20" />
                                        <label className="form-check-label" for="flexRadioDefault20">Private Vehicle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault21" />
                                        <label className="form-check-label" for="flexRadioDefault21">Commercial Vehicle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault22" />
                                        <label className="form-check-label" for="flexRadioDefault22">Goods & Carriage</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault23" />
                                        <label className="form-check-label" for="flexRadioDefault23">Garbage Truck</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault24" />
                                        <label className="form-check-label" for="flexRadioDefault24">Taxi/Hired Vehicle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault25" />
                                        <label className="form-check-label" for="flexRadioDefault25">Public Service Vehicle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault26" />
                                        <label className="form-check-label" for="flexRadioDefault26">Educational Institute Bus</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault27" />
                                        <label className="form-check-label" for="flexRadioDefault27">Others (Specify)</label>
                                    </div>
                                </div>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Driver of the offending vehicle</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name</label>
                                <input type="text" className="form-control" id="" placeholder="Rajesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Father's Name</label>
                                <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Mobile No.</label>
                                <input type="text" className="form-control" id="" placeholder="+919999999999" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail2" className="form-label">Driving Licence</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault28" />
                                        <label className="form-check-label" for="flexRadioDefault28">Permanent</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault29" />
                                        <label className="form-check-label" for="flexRadioDefault29">Learner’s</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault30" />
                                        <label className="form-check-label" for="flexRadioDefault30">Juvenile</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefaulyt31" />
                                        <label className="form-check-label" for="flexRadioDefaulyt31">Without License</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault32" />
                                        <label className="form-check-label" for="flexRadioDefault32">Others (Specify)</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Driving Licence No.</label>
                                <input type="text" className="form-control" id="" placeholder="HONDA001" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Period of Validity of Licence</label>
                                <input type="text" className="form-control" id="" placeholder="10 Years" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Licensing Authority</label>
                                <input type="text" className="form-control" id="" placeholder="Mr. Ramprakash" />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Owner of the offending vehicle</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name</label>
                                <input type="text" className="form-control" id="" placeholder="Rajesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Father's Name</label>
                                <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Mobile No.</label>
                                <input type="text" className="form-control" id="" placeholder="+919999999999" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Insurance of offending vehicle</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Policy No.</label>
                                <input type="text" className="form-control" id="" placeholder="11001100" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Period of Policy</label>
                                <input type="text" className="form-control" id="" placeholder="10 Year" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of Insurance Company</label>
                                <input type="text" className="form-control" id="" placeholder="RK Insurance Company" />
                            </div>
                            <div>
                                <div className="col-12 input-space-check">
                                    <label htmlFor="inputEmail4" className="form-label">
                                        Whether License has been verified from the Authority.
                                        <i>(If yes, attach report and If no, give reasons)</i>
                                    </label>
                                    <div className='d-flex flex-wrap'>
                                        <div className="form-check me-5 mb-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault4"
                                                id="flexRadioDefault33"
                                                value="yes"
                                                onChange={handleLicenseRadioChange}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault33">Yes</label>
                                        </div>
                                        <div className="form-check me-5 mb-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault4"
                                                id="flexRadioDefault34"
                                                value="no"
                                                onChange={handleLicenseRadioChange}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault34">No</label>
                                        </div>
                                    </div>
                                </div>
                                {isLicenseVerified === 'yes' && (
                                    <div className="col-12 input-space">
                                        <label htmlFor="fileUpload" className="form-label">Attach report</label><br />
                                        <input
                                            type="file"
                                            ref={fileInputRef1}
                                            onChange={(e) => handleFileChange(e, setFileName1)}
                                            style={{ display: 'none' }}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => handleUploadClick(fileInputRef1)}
                                        >
                                            <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                        </button>
                                        {fileName1 && (
                                            <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                                        )}
                                    </div>
                                )}

                                {isLicenseVerified === 'no' && (
                                    <div className="col-12 input-space">
                                        <label htmlFor="reason" className="form-label">Give reasons</label>
                                        <textarea className="form-control" id="reason" rows="3" placeholder='Description...'></textarea>
                                    </div>
                                )}
                            </div>
                            <div>
                                <div className="col-12 input-space-check">
                                    <label htmlFor="inputEmail4" className="form-label">
                                        Whether Driving Licence suspended/cancelled
                                        <i>(If yes, give details)</i>
                                    </label>
                                    <div className='d-flex flex-wrap'>
                                        <div className="form-check me-5 mb-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault5"
                                                id="flexRadioDefault35"
                                                value="yes"
                                                onChange={handleDrivingLicenseRadioChange}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault35">Yes</label>
                                        </div>
                                        <div className="form-check me-5 mb-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault5"
                                                id="flexRadioDefault36"
                                                value="no"
                                                onChange={handleDrivingLicenseRadioChange}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault36">No</label>
                                        </div>
                                    </div>
                                </div>

                                {isDrivingLicenseSuspended === 'yes' && (
                                    <div className="col-12 input-space">
                                        <label htmlFor="drivingReason" className="form-label">Give reasons</label>
                                        <textarea className="form-control" id="drivingReason" rows="3" placeholder='Description...'></textarea>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether driver injured during the accident
                                    <i>(If yes, give details)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault6"
                                            id="flexRadioDefault37"
                                            value="yes"
                                            onChange={handleDriverInjuredRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault37">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault6"
                                            id="flexRadioDefault38"
                                            value="no"
                                            onChange={handleDriverInjuredRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault38">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isDriverInjured === 'yes' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="injuredReason" className="form-label">
                                        Give details
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="injuredReason"
                                        rows="3"
                                        placeholder="Description..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Vehicle was driven by</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault39" />
                                        <label className="form-check-label" for="flexRadioDefault39">Owner</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault40" />
                                        <label className="form-check-label" for="flexRadioDefault40">Paid Driver</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault41" />
                                        <label className="form-check-label" for="flexRadioDefault41">Others (Specify)</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether the driver was driving under the influence of alcohol/drugs
                                    <i>(Whether findings based on scientific report. If yes, give details)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault8"
                                            id="flexRadioDefault42"
                                            value="yes"
                                            onChange={handleUnderInfluenceRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault42">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault8"
                                            id="flexRadioDefault43"
                                            value="no"
                                            onChange={handleUnderInfluenceRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault43">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isUnderInfluence === 'yes' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="influenceReason" className="form-label">
                                        Give details
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="influenceReason"
                                        rows="3"
                                        placeholder="Description..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether driver carrying mobile phone at the time of accident
                                    <i>(If yes, give details of Mobile)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault9"
                                            id="flexRadioDefault44"
                                            value="yes"
                                            onChange={handleCarryingMobileRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault44">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault9"
                                            id="flexRadioDefault45"
                                            value="no"
                                            onChange={handleCarryingMobileRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault45">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isCarryingMobile === 'yes' && (<>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">Mobile No.</label>
                                    <input type="text" className="form-control" id="" placeholder="+919999999999" />
                                </div>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">IMEI No.</label>
                                    <input type="text" className="form-control" id="" placeholder="9999" />
                                </div>
                                <div className="col-md-6 input-space">
                                    <label for="" className="form-label">Make & Model</label>
                                    <input type="text" className="form-control" id="" placeholder="9999" />
                                </div>
                            </>
                            )}
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether driver previously involved in motor accident case(s)
                                    <i>(If yes, whether case pending or decided by MACT? Give details of the FIR and MACT case)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault10"
                                            id="flexRadioDefault46"
                                            value="yes"
                                            onChange={handlePreviouslyInvolvedRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault46">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault10"
                                            id="flexRadioDefault47"
                                            value="no"
                                            onChange={handlePreviouslyInvolvedRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault47">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isPreviouslyInvolved === 'yes' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="accidentDetails" className="form-label">
                                        Give details of the FIR and MACT case
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="accidentDetails"
                                        rows="3"
                                        placeholder="Description..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="entry">
                                <p className='mb-32'>In case of commercial vehicle</p>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Permit details</label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Fitness details</label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether Permit and Fitness have been verified from the Authority
                                    <i>(If yes, attach report. If no, give reasons)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault11"
                                            id="flexRadioDefault48"
                                            value="yes"
                                            onChange={handlePermitRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault48">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault11"
                                            id="flexRadioDefault49"
                                            value="no"
                                            onChange={handlePermitRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault49">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isPermitVerified === 'yes' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="permitReport" className="form-label">
                                        Attach report
                                    </label>
                                    <br />
                                    <input
                                        type="file"
                                        ref={fileInputRef1}
                                        onChange={(e) => handleFileChange(e, setFileName1)}
                                        style={{ display: 'none' }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => handleUploadClick(fileInputRef1)}
                                    >
                                        <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                    </button>
                                    {fileName1 && (
                                        <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                                    )}
                                </div>
                            )}

                            {isPermitVerified === 'no' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="permitReasons" className="form-label">
                                        Give reasons
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="permitReasons"
                                        rows="3"
                                        placeholder="Description..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether the owner reported the accident to the Insurance Company
                                    <i>(If yes, give date)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault12"
                                            id="flexRadioDefault50"
                                            value="yes"
                                            onChange={handleAccidentReportedRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault50">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault12"
                                            id="flexRadioDefault51"
                                            value="no"
                                            onChange={handleAccidentReportedRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault51">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isAccidentReported === 'yes' && (
                                <div className="col-md-6 input-space">
                                    <label htmlFor="reportDate" className="form-label">
                                        Date of Reporting
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="reportDate"
                                        value={reportDate}
                                        onChange={(e) => setReportDate(e.target.value)}
                                    />
                                </div>
                            )}
                            {isAccidentReported === 'no' && (<></>)}
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    In case the driver fled from the spot, whether the owner produced the driver before the police
                                    <i>(If yes, attach the copy of notice under Section 133 of the Motor Vehicles Act)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault13"
                                            id="flexRadioDefault52"
                                            value="yes"
                                            onChange={handleDriverProducedRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault52">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault13"
                                            id="flexRadioDefault53"
                                            value="no"
                                            onChange={handleDriverProducedRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault53">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isDriverProduced === 'yes' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="fileUpload" className="form-label">
                                        Attach notice copy
                                    </label><br />
                                    <input
                                        type="file"
                                        ref={fileInputRef1}
                                        onChange={(e) => handleFileChange(e, setFileName1)}
                                        style={{ display: 'none' }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => handleUploadClick(fileInputRef1)}
                                    >
                                        <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                    </button>
                                    {fileName1 && (
                                        <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                                    )}
                                </div>
                            )}
                            {isDriverProduced === 'no' && (<></>)}

                            <div className="entry">
                                <p className='mb-32'>Victim(s) details </p>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail6" className="form-label">Victim(s) </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault54" />
                                        <label className="form-check-label" for="flexRadioDefault54">Pedestrian/Bystander</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault55" />
                                        <label className="form-check-label" for="flexRadioDefault55">Cyclist</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault56" />
                                        <label className="form-check-label" for="flexRadioDefault56">Two-wheeler</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault57" />
                                        <label className="form-check-label" for="flexRadioDefault57">In other Vehicle</label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault14" id="flexRadioDefault58" />
                                        <label className="form-check-label" for="flexRadioDefault58">Others (Specify) </label>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Death Case</h1>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age of the deceased </label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation </label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of Legal Representatives of the Deceased</p>
                            </div>
                            <div className="col-12 input-space">
                                {representatives.map((rep, index) => (
                                    <div key={index} className="d-flex flex-column input-space mb-0">
                                        <div className='row '>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={rep.name}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Relationship</label>
                                                <input
                                                    type="text"
                                                    name="relationship"
                                                    placeholder="Relationship"
                                                    value={rep.relationship}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Age</label>
                                                <input
                                                    type="number"
                                                    name="age"
                                                    placeholder="Age"
                                                    value={rep.age}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-start align-items-center mt-md-3 mt-0">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-3"
                                                    onClick={() => removeRepresentative(index)}><i class="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={addRepresentative}>
                                    Add Another Representative
                                </button>
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Injury Case</h1>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the injured</label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age</label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation</label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Nature of Injury </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault15" id="flexRadioDefault59" />
                                        <label className="form-check-label" for="flexRadioDefault59">Simple </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault15" id="flexRadioDefault60" />
                                        <label className="form-check-label" for="flexRadioDefault60">Grievous </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Details of Injury</label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Offences Charged</p>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Indian Penal Code, 1860</label>
                                <div className='d-flex flex-wrap'>
                                    <div className="row d-flex justify-content-start">
                                        <div className='col-md-6'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="section279" />
                                                <label className="form-check-label" for="section279">
                                                    Section 279 - Rash driving or riding on a public way
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="section337" />
                                                <label className="form-check-label" for="section337">
                                                    Section 337 - Causing hurt by act endangering life or personal safety of others
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="section338" />
                                                <label className="form-check-label" for="section338">
                                                    Section 338 - Causing grievous hurt by act endangering life or personal safety<br /> of others
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="section304A" />
                                                <label className="form-check-label" for="section304A">
                                                    Section 304-A - Causing death by negligence
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="ohteroffence" />
                                                <label className="form-check-label" for="ohteroffence">
                                                    Any other offence
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Motor Vehicles Act, 1988 </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="row d-flex justify-content-start">
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="Sections3/181" />
                                                <label className="form-check-label" for="Sections3/181">
                                                    Sections 3/181 - Driving without license
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="Sections4/181" />
                                                <label className="form-check-label" for="Sections4/181">
                                                    Sections 4/181 - Driving by minor
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="section5/180" />
                                                <label className="form-check-label" for="section5/180">
                                                    Sections 5/180 - Allowing unauthorized person to drive
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="section182" />
                                                <label className="form-check-label" for="section182">
                                                    Section 182 - Offences relating to licences
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="Section56/192" />
                                                <label className="form-check-label" for="Section56/192">
                                                    Sections 56/192 - Without fitness
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="66(1)/192A" />
                                                <label className="form-check-label" for="66(1)/192A">
                                                    Sections 66(1)/192A - Without permit
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="112/183(1)" />
                                                <label className="form-check-label" for="112/183(1)">
                                                    Sections 112/183(1) - Over speeding
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="113/194" />
                                                <label className="form-check-label" for="113/194">
                                                    Sections 113/194 - Over loading
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="119/184" />
                                                <label className="form-check-label" for="119/184">
                                                    Sections 119/184 - Jumping red light
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="119/177" />
                                                <label className="form-check-label" for="119/177">
                                                    Sections 119/177  - Violation of mandatory signs (One way, No right turn, No left turn)
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="122/177" />
                                                <label className="form-check-label" for="122/177">
                                                    Sections 122/177 - Improper/ obstructive parking
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="146/196" />
                                                <label className="form-check-label" for="146/196">
                                                    Sections 146/196 - Without insurance
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="177/RRR17(1)" />
                                                <label className="form-check-label" for="177/RRR17(1)">
                                                    Section 177/RRR17(1) - Violation of “One way”
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194(1A)/RRR29" />
                                                <label className="form-check-label" for="194(1A)/RRR29">
                                                    Section 194(1A)/RRR29 - Carrying High/Long Load
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="184/RRR6" />
                                                <label className="form-check-label" for="184/RRR6">
                                                    Section 184/RRR6 - Violation of “No overtaking”
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="177/CMVR 105" />
                                                <label className="form-check-label" for="177/CMVR 105">
                                                    Section 177/CMVR 105 - Without light after sunset
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="179" />
                                                <label className="form-check-label" for="179">
                                                    Section 179 - Disobedience of orders, obstruction and refusal of information
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="184" />
                                                <label className="form-check-label" for="184">
                                                    Section 184 - Driving dangerously
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="1842" />
                                                <label className="form-check-label" for="1842">
                                                    Section 184 - Using mobile phone while driving
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="185" />
                                                <label className="form-check-label" for="185">
                                                    Section 185 - Drunken driving/ drugs
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="186" />
                                                <label className="form-check-label" for="186">
                                                    Section 186 - Driving when mentally or physically unfit to drive
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="187" />
                                                <label className="form-check-label" for="187">
                                                    Section 187 - Violation of Sections 132(1)(a), 133 & 134
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="190" />
                                                <label className="form-check-label" for="190">
                                                    Section 190 - Using vehicle in unsafe condition
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194A" />
                                                <label className="form-check-label" for="194A">
                                                    Section 194A - Carrying more passengers than authorised
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194B/CMVR138(3)" />
                                                <label className="form-check-label" for="194B/CMVR138(3)">
                                                    Section 194B/ CMVR 138(3) - Driving without a safety belt
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194C" />
                                                <label className="form-check-label" for="194C">
                                                    Section 194C - Penalty for violation of safety measures for motor cycle driver <br /> and pillion rider
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194D" />
                                                <label className="form-check-label" for="194D">
                                                    Section 194D - Penalty for not wearing protective headgear
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194E" />
                                                <label className="form-check-label" for="194E">
                                                    Section 194E - Failure to allow free passage to emergency vehicles
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="194F" />
                                                <label className="form-check-label" for="194F">
                                                    Section 194F - Using the horn unnecessarily or in places where it is prohibited
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="197" />
                                                <label className="form-check-label" for="197">
                                                    Section 197 - Taking vehicle without authority
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="199A" />
                                                <label className="form-check-label" for="199A">
                                                    Section 199A - Offence committed by juvenile
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="ohteroffence2" />
                                                <label className="form-check-label" for="ohteroffence2">
                                                    Any other offence
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Detailed description of the Accident</p>
                            </div>
                            <div className="col-12 input-space">
                                <TextEditor />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Direction(s) required from the Claims Tribunal </p>
                            </div>
                            <ol type='i'>
                                <li>
                                    <div className="col-12 input-space">
                                        <p className='mb-0'>
                                            The driver of the offending vehicle has not furnished Form-III/has furnished incomplete Form-III, despite letter(s) dated
                                            <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' />
                                            [Copy (s) attached].
                                            The driver be directed to furnish the Form-III before this Tribunal within 15 days.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="col-12 input-space">
                                        <p className='mb-0'>
                                            The owner of the offending vehicle has not furnished Form-IV/ has furnished incomplete Form-IV, despite letter(s) dated
                                            <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' />
                                            [Copy (s) attached].
                                            The owner may be directed to furnish the Form-IV before this Tribunal within 15 days.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="col-12 input-space">
                                        <p className='mb-0'>
                                            The victim(s) of the accident has/have not furnished Form-VIA/ Form-VIB/ has  furnished  incomplete  Form-VIA/  Form-VIB,
                                            despite  letter(s)   dated
                                            <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' />
                                            [Copy (s) attached]. The victim may be directed to furnish
                                            the Form-VIA/ Form-VIB before this Tribunal within 15 days.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="col-12 input-space">
                                        <p className='mb-0'>
                                            The Registration Authority has not given the Verification Report despite letter(s) dated
                                            <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' />
                                            [Copy (s) attached]. The Registration Authority be directed to furnish the Verification Report directly before this Tribunal
                                            within 15 days.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="col-12 input-space">
                                        <p className='mb-0'>
                                            The Hospital has not given the MLC/ Post Mortem report despite letter(s) dated
                                            <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' />
                                            [Copy (s) attached]. The Hospital be directed to furnish the above-mentioned documents directly before this Tribunal within 15
                                            days.
                                        </p>
                                    </div>
                                </li>
                            </ol>
                            <div className="entry">
                                <p className='mb-32'>Documents to be attached </p>
                            </div>
                            <div className="col-12 input-space">
                                <div className="table-responsive">                              
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">no</th>
                                            <th scope="col">Document </th>
                                            <th scope="col">Attached </th>
                                            <th scope="col">Not Attached </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>FIR</td>
                                            <td><i class="fa-solid fa-check text-success"></i></td>
                                            <td><i class="fa-solid fa-xmark text-danger"></i></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Form-I - First Accident Report (FAR) </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Form-II - Rights of Victim(s) and Flow Chart </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>Form-III	-	Driver’s documents submitted </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>
                                                Form-IV   -	Owner’s Form along with documents submitted
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">6</th>
                                            <td>Form-V - Interim Accident Report (IAR) along with documents submitted </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">7</th>
                                            <td>Form-VIA-	Victim’s	Form	along	with documents submitted </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <td>Form-VIB - Details of minor children of the Victim along with documents submitted </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <td>Form-VII- Detailed Accident Report (DAR) </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">9</th>
                                            <td>Form-VIII - Site Plan </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <td>Form-IX - Mechanical Inspection Report </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">11</th>
                                            <td>Form-X - Verification Report </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">12</th>
                                            <td>Form-XI - Insurance Form along with documents submitted </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">13</th>
                                            <td>Photographs of the scene of accident from all angles </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">14</th>
                                            <td>Photographs of all the vehicles involved in the accident from all angles </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">15</th>
                                            <td>CCTV Footage of the accident </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">16</th>
                                            <td>Report under Section 173 CrPC </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">17</th>
                                            <td>Copy of notice under Section 133 of the Motor Vehicles Act </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">18</th>
                                            <td>Post-Mortem Report </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">19</th>
                                            <td>Medico Legal Case (MLC) form </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">20</th>
                                            <td>Multi angle photographs of the injured </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">21</th>
                                            <td>Letter(s) of the Investigating Officer demanding	the	relevant information/documents from the driver </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">22</th>
                                            <td>Letter(s) of the Investigating Officer demanding the relevant information/documents from the owner </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">23</th>
                                            <td>Letter(s) of the Investigating Officer demanding the relevant information/documents from the Insurance Company </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">24</th>
                                            <td>Letter(s) of the Investigating Officer demanding the relevant information/documents from the Victim(s) </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">25</th>
                                            <td>Letter(s) of the Investigating Officer demanding the relevant information/documents from the Registration Authorities </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">26</th>
                                            <td>Letter(s) of the Investigating Officer demanding the relevant information/documents from the Hospital </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Verification :</p>
                            </div>
                            <div className="col-12 input-space">
                                <p className='mb-0'>
                                    Verified at
                                    <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0'
                                        name="location" />
                                    on this
                                    <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0'
                                        name="day" />
                                    day of
                                    <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0'
                                        name="month" />
                                    that the contents of the above report are true and correct and the documents were gathered during investigation.
                                </p>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>S.H.O./I.O</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">P.I.S. No.</label>
                                <input type="number" className="form-control" id="" placeholder="0001"/>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Phone No.</label>
                                <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">P.S.</label>
                                <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date</label>
                                <input type="date" className="form-control" id="" value="2024-09-21"/>
                            </div>
                            <div className="col-md-6 input-space mb-3">
                            <label className="form-label">SHO/RG </label>
                            {<SignatureComponent/>}
                            </div>
                            <div className="col-md-6 input-space mb-3">
                            <label className="form-label">ACP/RG </label>
                            {<SignatureComponent/>}
                        </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/FormEight"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSeven