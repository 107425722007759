import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const ComplaintForm = () => {

    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);

    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');

    const [isEditable, setIsEditable] = useState(false);

    const handleUploadClick1 = () => {
        fileInputRef1.current.click();
    };

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName1(file.name);
        }
    };

    const handleUploadClick2 = () => {
        fileInputRef2.current.click();
    };

    const handleFileChange2 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName2(file.name);
        }
    };

    const handleEditClick = () => {
        setIsEditable((prev) => !prev);
    };

    return (
        <div>
        <Helmet>
            <title>Complaint Form - FIR Management System</title>
        </Helmet>
            <div className='container'>
                <div className='pt-3 text-end'>
                    <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
                </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1>Complaint Form</h1>
                            <p>Fill the All data. It will take a couple of minutes. </p>
                        </div>
                        <div className="edit-event-no">
                            <div className="border-edit">
                                <div>
                                    <label htmlFor="inputPassword6" className="col-form-label py-0">Event ID :</label>
                                </div>
                                <div className='input-width'>
                                    <input
                                        type="text"
                                        id="inputPassword6"
                                        className="form-control shadow-none"
                                        aria-describedby="passwordHelpInline" placeholder='001'
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className='pencil'>
                                    <Link to="#" id="passwordHelpInline" className="form-text" onClick={handleEditClick}>
                                        <i className="fa-solid fa-pencil"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Call 112 ?</label>
                                <div className='d-flex'>
                                    <div className="form-check me-5">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">PCR Assign</label>
                                <select id="inputState" className="form-select">
                                    <option selected>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Person Assign</label>
                                <select id="inputState" className="form-select">
                                    <option selected>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">PCR No</label>
                                <input type="text" className="form-control" id="" placeholder="DL01AJ1121" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">PCR Person</label>
                                <input type="text" className="form-control" id="" placeholder="ASI Mukesh" />
                            </div>
                            <div className="col-12">
                                <TextEditor />
                            </div>
                            <div className="col-12 mt-32">
                                <input
                                    type="file"
                                    ref={fileInputRef1}
                                    onChange={handleFileChange1}
                                    style={{ display: 'none' }}
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleUploadClick1}
                                >
                                    <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                </button>
                                {fileName1 && (
                                    <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                                )}
                            </div>
                        </form>

                        <form className="row mt-32">
                            <div className="col-12 input-space-check">
                                <label for="inputEmail4" className="form-label">Injured ?</label>
                                <div className='d-flex'>
                                    <div className="form-check me-5">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                        <label className="form-check-label" for="flexRadioDefault3">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                        <label className="form-check-label" for="flexRadioDefault4">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Hospital Name</label>
                                <input type="text" className="form-control" id="" placeholder="PP Savani Hospital" />
                            </div>
                            <div className="col-md-6 ">
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Injured Person Name</label>
                                <input type="text" className="form-control" id="" placeholder="Person Name" />
                            </div>
                            <div className="col-md-6 ">
                            </div>
                            <div className="col-12">
                                <TextEditor />
                            </div>
                            <div className="col-12 mt-32">
                                <input
                                    type="file"
                                    ref={fileInputRef2}
                                    onChange={handleFileChange2}
                                    style={{ display: 'none' }}
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleUploadClick2}
                                >
                                    <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                </button>
                                {fileName2 && (
                                    <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                                )}
                            </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/FirstInformationReport"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComplaintForm;
