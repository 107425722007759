import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Index = () => {
  return (
    <div>
        <Helmet>
          <title>FIR Management System</title>
        </Helmet>
        <div className='container py-100 main-home-link'>
            <div className="row g-3">
                <div className="col-12 main-home-link">
                    <div>
                        <div className="form-title">
                            <h1>BNS</h1>
                        </div>
                        <div className='form-border'>
                          <p><Link to={"/ComplaintForm"}><i class="fa-solid fa-arrow-right"></i> Complaint Form</Link></p>  
                          <p><Link to={"/FirstInformationReport"}><i class="fa-solid fa-arrow-right"></i> First Information Report</Link></p>  
                          <p><Link to={"/GeneralDiaryGD"}><i class="fa-solid fa-arrow-right"></i> General Diary GD</Link></p>  
                          <p><Link to={"/ArrestMemo"}><i class="fa-solid fa-arrow-right"></i> Arrest Memo</Link></p>  
                          <p><Link to={"/PersonalSearchMemo"}><i class="fa-solid fa-arrow-right"></i> Personal Search Memo</Link></p>  
                          <p><Link to={"/MedicalExaminationForm"}><i class="fa-solid fa-arrow-right"></i> Medical Examination Form</Link></p>  
                          <p><Link to={"/ConvictionSlip"}><i class="fa-solid fa-arrow-right"></i> Conviction Slip</Link></p>  
                          <p><Link to={"/AgeMemo"}><i class="fa-solid fa-arrow-right"></i> Age Memo</Link></p>  
                          <p><Link to={"/InnerCaseDiary"}><i class="fa-solid fa-arrow-right"></i> Inner Case Diary</Link></p>  
                          <p className='mb-0'><Link to={"/ConvictionSlipTwo"}><i class="fa-solid fa-arrow-right"></i> Conviction Slip Two</Link></p>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index