import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import { Helmet } from 'react-helmet';

const FormFourteen = () => {

    const [hasDisability, setHasDisability] = useState('no');
    const [disabilityDetails, setDisabilityDetails] = useState('');

    const handleDisabilityChange = (event) => {
        setHasDisability(event.target.value);
    };

    const handleDetailsChange = (event) => {
        setDisabilityDetails(event.target.value);
    };

    return (
        <div>
            <Helmet>
                <title>Format Of Written Submissions To Be Filed By The Parties Ininjury Cases - FIR Management System</title>
            </Helmet>
            <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Format Of Written Submissions To Be Filed By The Parties Ininjury Cases </h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date of accident</label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the injured</label>
                                <input type="text" className="form-control" id="" placeholder="Mukesh Rathore" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age of the injured </label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation of the injured</label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Income of the injured </label>
                                <input type="number" className="form-control" id="" placeholder="Enter monthly income" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Nature of injury</label>
                                <input type="text" className="form-control" id="" placeholder="Enter Nature of injury" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Medical treatment taken by the injured</label>
                                <input type="text" className="form-control" id="" placeholder="Enter Medical treatment" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Period of hospitalization</label>
                                <input type="text" className="form-control" id="" placeholder="Enter Period of hospitalization" />
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether any permanent disability?
                                    <i>(If yes, give details)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check form-check me-5 mb-3">
                                        <input
                                            className="form-check-input p-0"
                                            type="radio"
                                            name="disability"
                                            id="disabilityYes"
                                            value="yes"
                                            checked={hasDisability === 'yes'}
                                            onChange={handleDisabilityChange}
                                        />
                                        <label className="form-check-label" htmlFor="disabilityYes">Yes</label>
                                    </div>
                                    <div className="form-check form-check me-5 mb-3">
                                        <input
                                            className="form-check-input p-0"
                                            type="radio"
                                            name="disability"
                                            id="disabilityNo"
                                            value="no"
                                            checked={hasDisability === 'no'}
                                            onChange={handleDisabilityChange}
                                        />
                                        <label className="form-check-label" htmlFor="disabilityNo">No</label>
                                    </div>
                                </div>

                                {hasDisability === 'yes' && (
                                    <div className="mb-3">
                                        <label htmlFor="disabilityDetails" className="form-label">
                                            If yes, give details
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="disabilityDetails"
                                            placeholder="Provide details of the disability"
                                            value={disabilityDetails}
                                            onChange={handleDetailsChange}
                                            rows="3"
                                        ></textarea>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="reason" className="form-label">Photographs of the injured and the injuries</label>
                                <textarea className="form-control" id="reason" rows="3" placeholder='Description...'></textarea>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Computation of Compensation: </p>
                            </div>
                            <div className="col-12 input-space mb-0">
                                <div className="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "40%" }}>Heads </th>
                                            <th scope="col" style={{ width: "30%" }}>Claim of Petitioners(s) </th>
                                            <th scope="col" style={{ width: "30%" }}>Response of Respondent(s) </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan='3'>Pecuniary Loss: </td>
                                        </tr>
                                        <tr>
                                            <td>Expenditure on treatment </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Expenditure on conveyance </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Expenditure on special diet </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Cost of nursing/attendant </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of income </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Cost of artificial limb (if applicable) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any other loss/expenditure </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td colspan='3'>Non-Pecuniary Loss: </td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for mental and physical shock </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Pain and suffering </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of amenities of life </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Disfiguration </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of marriage prospects </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of earning, inconvenience, hardships, disappointment, frustration, mental stress, dejectment and unhappiness in life
                                                etc. </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td colspan='3'>Disability resulting in loss of earning capacity: </td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of disability assessed and nature of disability as permanent or temporary  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of amenities or loss of expectation of life span on account of disability </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of loss of earning capacity in relation to disability </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of future Income - (Income x % Earning Capacity x Multiplier) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount" type="number"></input></td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL COMPENSATION  </td>
                                            <td><p> ₹9999</p></td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                        <tr>
                                            <td>INTEREST </td>
                                            <td><p> ₹9999</p></td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/FormFifteen"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormFourteen