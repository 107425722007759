import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormSixB = () => {
    
    const fileInputRef0 = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
    const fileInputRef5 = useRef(null);
    const fileInputRef6 = useRef(null);

    const [fileName0, setFileName0] = useState('');
    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');
    const [fileName3, setFileName3] = useState('');
    const [fileName4, setFileName4] = useState('');
    const [fileName5, setFileName5] = useState('');
    const [fileName6, setFileName6] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Victim's form relating to minor children of vitim(s) - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Victim's form relating to minor children of vitim(s)</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of the Minor Children (18 years or below)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age/Date of Birth</label>
                            <input type="text" className="form-control" id="" value='2002-01-20'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Sex</label>
                            <select id="inputState" className="form-select">
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Category</label>
                            <select id="inputState" className="form-select">
                                <option selected>SC</option>
                                <option>ST</option>
                                <option>OBC</option>
                                <option>General</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father’s Name</label>
                            <input type="text" className="form-control" id="" placeholder="Ramnikbhai"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father’s Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rekhaben"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Guardian’s Name (If different from parent)</label>
                            <input type="text" className="form-control" id="" placeholder="Ramilaben"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Family Income (Annual) </label>
                            <input type="time" className="form-control" id="" placeholder='500000'/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Permanent Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Present Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Contact No. of father/mother/family member</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether the child is Differently abled: If yes, give details</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Present living conditions/ economic condition (after the accident)</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Educational details of children</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Current status of education</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Level	of	education (class)</label>
                            <input type="text" className="form-control" id="" placeholder="1st"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Whether the child is enrolled under EWS quota</label>
                            <select id="inputState" className="form-select">
                                <option selected>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">If not  attending school, reasons to be provided</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Detailed information of the school where the child is studying</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Corporation/ Municipal/ Panchayat</label>
                            <input type="text" className="form-control" id="" placeholder="Corporation"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Govt./Other Boards</label>
                            <input type="text" className="form-control" id="" placeholder="Govt."/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Private Management</label>
                            <input type="text" className="form-control" id="" placeholder="Private Management"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Expenditure on education</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Monthly school tuition fee</label>
                            <input type="text" className="form-control" id="" placeholder="1000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Annual school fee</label>
                            <input type="text" className="form-control" id="" placeholder="12000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Private tuition / coaching fee</label>
                            <input type="text" className="form-control" id="" placeholder="1000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Any other expenditure / logistics fee</label>
                            <input type="text" className="form-control" id="" placeholder="2000"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Vocational training / skill development, if any</p>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Type of skill development</label>
                            <input type="text" className="form-control" id="" placeholder="Learning..."/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Cost involved</label>
                            <input type="text" className="form-control" id="" placeholder="Cost involved"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Health and Nutrition</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Physical health condition of the child (including medical examination report, in case of any disability)</p>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Any injury to child. If yes, details to be given</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Loss of any body part due to accident</label>
                            <input type="text" className="form-control" id="" placeholder="Loss of any body part"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Mental health condition of the child</p>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether immediate psychological counseling / treatment/ support required</label>
                            <input type="text" className="form-control" id="" placeholder="Description..."/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Whether long term support required</label>
                            <input type="text" className="form-control" id="" placeholder="Description..."/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Medical expenses, if any</p>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Cost involved in immediate medical treatment / Cost involved in long term medical treatments</label>
                            <input type="text" className="form-control" id="" placeholder="Description..."/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Diet and nutrition expenses</label>
                            <input type="text" className="form-control" id="" placeholder="Description..."/>
                        </div>

                        <div className="entry">
                            <p className='mb-32'>Documents to be submitted </p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">1. Copy of school/educational institution ID</label><br/>
                            <label className="form-label">2. Copy of Aadhar card</label><br/>
                            <label className="form-label">3. Proof of education fee</label><br/>
                            <label className="form-label">4. Proof of other expenses/expenditure of the children</label><br/>
                            <label className="form-label">5. Copy of medical documents</label><br/>
                            <label className="form-label">6. Disability Certificate, if applicable</label><br/>
                            <label className="form-label">7. Copy of Caste certificate, if applicable</label><br/>
                            <label className="form-label">8. Copy of Income certificate, if applicable</label><br/>
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} multiple/>
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Verification :</p>
                        </div>
                        <div className="col-12 input-space">
                            <p className='mb-0'>
                                Verified at
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="location" value={formData.location} onChange={handleChange} />
                                on this
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="day" value={formData.day} onChange={handleChange} />
                                day of
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="month" value={formData.month} onChange={handleChange} />
                                that the contents of the above Form are true to my knowledge and the
                                documents attached are true copies of their originals.
                            </p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label className="form-label">Victim(s)</label>
                            {<SignatureComponent/>}
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Name and photograph of all the Minor Children</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Mukesh Rao"/>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">Upload Photograph</label><br />
                            <input type="file" ref={fileInputRef2} onChange={(e) => handleFileChange(e, setFileName2)} style={{ display: 'none' }} multiple/>
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef2)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName2 && (
                                <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                            )}
                        </div>
                        <div className="entry">
                            <p className='mb-3'>Note :</p>
                        </div>
                        <div className="col-12 input-space mb-0">
                            <label className="form-label"><i>1. Forms-VIA and VIB to be sent by Investigating Officer to the concerned Child Welfare Committee to ascertain if the Child in Need of Care and Protection (CNCP).</i></label><br/>
                            <label className="form-label"><i>2. Copy of Forms-VIA and VIB to be sent to Delhi State Legal Services Authority (DSLSA) to assign a lawyer to assist the child/children to avail their legal remedies/rights.</i></label><br/>
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormSeven"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormSixB