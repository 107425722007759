import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FormOne = () => {
    
    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };
    
  return (
      <div>
      <Helmet>
          <title>First Accident Report (FAR) - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>First Accident Report (FAR)</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of Accident</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Time of Accident</label>
                            <input type="time" className="form-control" id="" value="10:10"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Place of Accident</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Source of Information</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Driver/Owner</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Victim</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Witness</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Hospital</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label" for="flexRadioDefault5">Good Samaritan</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                    <label className="form-check-label" for="flexRadioDefault6">Police</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                                    <label className="form-check-label" for="flexRadioDefault7">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Name, mobile number & address of the Informant</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pawan Pachauri"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No.</label>
                            <input type="number" className="form-control" id="" placeholder='+919999999999'/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Nature of Accident</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault8" />
                                    <label className="form-check-label" for="flexRadioDefault8">Injury</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault9" />
                                    <label className="form-check-label" for="flexRadioDefault9">Fatal</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault10" />
                                    <label className="form-check-label" for="flexRadioDefault10">Damage/loss of the property</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault11" />
                                    <label className="form-check-label" for="flexRadioDefault11">Any other loss/injury</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Number of Vehicles involved</label>
                            <input type="number" className="form-control" id="" placeholder="10"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether Registration Number of the Offending Vehicle known</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault12" />
                                    <label className="form-check-label" for="flexRadioDefault12">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault13" />
                                    <label className="form-check-label" for="flexRadioDefault13">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether	offending vehicle impounded by the police</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault14" />
                                    <label className="form-check-label" for="flexRadioDefault14">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault15" />
                                    <label className="form-check-label" for="flexRadioDefault15">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the driver of the offending vehicle found on the spot</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault16" />
                                    <label className="form-check-label" for="flexRadioDefault16">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault17" />
                                    <label className="form-check-label" for="flexRadioDefault17">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Number of Fatalities</label>
                            <input type="number" className="form-control" id="" placeholder="10"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Number of Injured</label>
                            <input type="number" className="form-control" id="" placeholder="10"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of the Hospital where victim(s) taken</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Hospital Name</label>
                            <input type="text" className="form-control" id="" placeholder="Bims Hospital"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Doctor’s Name</label>
                            <input type="text" className="form-control" id="" placeholder="Dr. Jadeja"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Availability of CCTV Footage</p>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">If yes, CCTV Footage be preserved and be filed with DAR</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault18" />
                                    <label className="form-check-label" for="flexRadioDefault18">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault19" />
                                    <label className="form-check-label" for="flexRadioDefault19">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Owner(s), Driver(s) and Insurance of the Vehicle(s)</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Vehicle 1 (Offending vehicle)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle Registration No</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of the Driver</label>
                            <input type="text" className="form-control" id="" placeholder="Mr. Ramprakash"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address of Driver</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No. of Driver</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of the Owner</label>
                            <input type="text" className="form-control" id="" placeholder="Dr. Jadeja"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address of Owner</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No. of Owner</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Insurance Policy No.</label>
                            <input type="text" className="form-control" id="" placeholder="11001100"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Insurance Policy</label>
                            <input type="text" className="form-control" id="" placeholder="10 Year"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="RK Insurance Company"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Vehicle 2 (Offending vehicle)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Vehicle Registration No</label>
                            <input type="text" className="form-control" id="" placeholder="HONDA001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of the Driver</label>
                            <input type="text" className="form-control" id="" placeholder="Mr. Ramprakash"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address of Driver</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No. of Driver</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of the Owner</label>
                            <input type="text" className="form-control" id="" placeholder="Dr. Jadeja"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address of Owner</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Mobile No. of Owner</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Insurance Policy No.</label>
                            <input type="text" className="form-control" id="" placeholder="11001100"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Insurance Policy</label>
                            <input type="text" className="form-control" id="" placeholder="10 Year"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="RK Insurance Company"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Victim(s) (I)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Deceased /Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Deceased /Injured"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address & Contact Details</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Victim(s) (II)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Deceased /Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Deceased /Injured"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address & Contact Details</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Victim(s) (III)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Deceased /Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Deceased /Injured"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address & Contact Details</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Victim(s) (IV)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Deceased /Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Deceased /Injured"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address & Contact Details</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Victim(s) (V)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Deceased /Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Deceased /Injured"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address & Contact Details</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of Victim(s) (VI)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Deceased /Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Deceased /Injured"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Address & Contact Details</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>S.H.O./I.O</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.I.S. No.</label>
                            <input type="number" className="form-control" id="" placeholder="0001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Phone No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.S.</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Documents to be attached</p>
                        </div>
                        <div className="col-12">
                        <div className="col-md-6 input-space mb-0">
                            <label for="" className="form-label">(1) Copy of FIR</label><br/>
                            <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={(e) => handleFileChange(e, setFileName1)}
                                style={{ display: 'none' }}
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleUploadClick(fileInputRef1)}
                            >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div> 
                        </div>  
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormTwo"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormOne