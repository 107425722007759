import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormEleven = () => {
    const [isFiledWithin30Days, setIsFiledWithin30Days] = useState(null);
    const [fileName1, setFileName1] = useState('');
    const fileInputRef1 = useRef(null);

    const handleFormFilingRadioChange = (e) => {
        setIsFiledWithin30Days(e.target.value);
    };

    const handleFileChange = (e, setFileName) => {
        setFileName(e.target.files[0]?.name || '');
    };

    const handleUploadClick = (fileInputRef) => {
        fileInputRef.current.click();
    };

    const [representatives, setRepresentatives] = useState([
        { name: '', relationship: '', age: '' },
    ]);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newRepresentatives = [...representatives];
        newRepresentatives[index][name] = value;
        setRepresentatives(newRepresentatives);
    };

    const addRepresentative = () => {
        setRepresentatives([...representatives, { name: '', relationship: '', age: '' }]);
    };

    const removeRepresentative = (index) => {
        const newRepresentatives = representatives.filter((_, i) => i !== index);
        setRepresentatives(newRepresentatives);
    };

    return (
        <div>
            <Helmet>
                <title>Insurance Form - FIR Management System</title>
            </Helmet>
            <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
                <div className="forms py-100">
                    <div className="edit-event">
                        <div className="form-title">
                            <h1 className='mb-0'>Insurance Form</h1>
                        </div>
                    </div>
                    <div className="main-form">
                        <form className="row">
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">FIR No</label>
                                <input type="text" className="form-control" id="" placeholder="84721" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Date</label>
                                <input type="date" className="form-control" id="" value="2024-09-21" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Under Section </label>
                                <select id="inputState" className="form-select">
                                    <option selected>154 cr pc</option>
                                    <option>154 cr pc</option>
                                </select>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="inputState" className="form-label">Police Station</label>
                                <select id="inputState" className="form-select">
                                    <option selected>Sadar, Bazar , Delehi</option>
                                    <option>Sadar, Bazar , Delehi</option>
                                </select>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Vehicle Details</p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Registration No.</label>
                                <input type="text" className="form-control" id="" placeholder="001" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Vehicle Make</label>
                                <input type="text" className="form-control" id="" placeholder="Vehicle Make" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Vehicle Model</label>
                                <input type="text" className="form-control" id="" placeholder="2010" />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of Insured </p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name</label>
                                <input type="text" className="form-control" id="" placeholder="Rajesh Rathore" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Policy Details </p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Policy No.</label>
                                <input type="text" className="form-control" id="" placeholder="11001100" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Period of Policy</label>
                                <input type="text" className="form-control" id="" placeholder="10 Year" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Nature/Type of Policy </label>
                                <input type="text" className="form-control" id="" placeholder="RK Insurance Company" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of Accident </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of intimation of the accident by the Insured to the Insurance Company </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of receipt of FAR </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of receipt of IAR </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of receipt of DAR </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of appointment of the Designated Officer by the Insurance Company </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of Designated Officer </p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name</label>
                                <input type="text" className="form-control" id="" placeholder="Rajesh Rathore" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of appointment of the Surveyor/Investigator </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Name and Address of Surveyor/ Investigator </p>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name</label>
                                <input type="text" className="form-control" id="" placeholder="Rajesh Rathore" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Address</label>
                                <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of Report of the Surveyor/Investigator </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Date of Decision  of the Designated Officer </label>
                                <input type="date" className="form-control" id="" />
                            </div>
                            <div className="col-12 input-space-check">
                                <label htmlFor="inputEmail4" className="form-label">
                                    Whether this Form has been filed within 30 days of receipt of DAR
                                    <i>(If not, give reasons for delay)</i>
                                </label>
                                <div className="d-flex flex-wrap">
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefaultFiling"
                                            id="flexRadioDefaultFilingYes"
                                            value="yes"
                                            onChange={handleFormFilingRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultFilingYes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefaultFiling"
                                            id="flexRadioDefaultFilingNo"
                                            value="no"
                                            onChange={handleFormFilingRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultFilingNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {isFiledWithin30Days === 'no' && (
                                <div className="col-12 input-space">
                                    <label htmlFor="delayReason" className="form-label">Give reasons for delay</label>
                                    <textarea
                                        className="form-control"
                                        id="delayReason"
                                        rows="3"
                                        placeholder="Description of reasons for delay..."
                                    ></textarea>
                                </div>
                            )}
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Death Case</h1>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the deceased </label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age of the deceased </label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation </label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Monthly Income </label>
                                <input type="number" className="form-control" id="" placeholder="Enter monthly income" />
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Details of Legal Representatives of the Deceased</p>
                            </div>
                            <div className="col-12 input-space">
                                {representatives.map((rep, index) => (
                                    <div key={index} className="d-flex flex-column input-space mb-0">
                                        <div className='row '>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={rep.name}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Relationship</label>
                                                <input
                                                    type="text"
                                                    name="relationship"
                                                    placeholder="Relationship"
                                                    value={rep.relationship}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 input-space mb-3">
                                                <label for="" className="form-label">Age</label>
                                                <input
                                                    type="number"
                                                    name="age"
                                                    placeholder="Age"
                                                    value={rep.age}
                                                    onChange={(event) => handleChange(index, event)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-3 d-flex justify-content-start align-items-center mb-3 mt-md-3 mt-0">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-3"
                                                    onClick={() => removeRepresentative(index)}><i class="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={addRepresentative}>
                                    Add Another Representative
                                </button>
                            </div>
                            <div className="col-12 input-space">
                                <div className="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60%" }}>Computation of compensation </th>
                                            <th scope="col" style={{ width: "40%" }}>Amount in Rs. </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Income of the deceased (A) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Add-Future Prospects (B) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Less-Personal expenses of the deceased (C) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Monthly loss of dependency [(A+B) – C = D] </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Annual loss of dependency (D x 12) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Multiplier (E) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total loss of dependency (E x 12 x D = F) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Medical Expenses (G) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for loss of consortium (H) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation of loss for love and affection (I) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for loss of estate (J) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation towards funeral expenses (K) </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total Compensation (F+ G + H + I+J+K = L)  </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div className="edit-event">
                                <div className="form-title">
                                    <h1 className='mb-0'>Injury Case</h1>
                                </div>
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Name of the injured</label>
                                <input type="text" className="form-control" id="" placeholder="Enter name" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Age</label>
                                <input type="number" className="form-control" id="" placeholder="Enter age" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Occupation</label>
                                <input type="text" className="form-control" id="" placeholder="Enter occupation" />
                            </div>
                            <div className="col-md-6 input-space">
                                <label for="" className="form-label">Monthly Income </label>
                                <input type="number" className="form-control" id="" placeholder="Enter monthly income" />
                            </div>
                            <div className="col-12 input-space-check">
                                <label for="inputEmail3" className="form-label">Nature of Injury </label>
                                <div className='d-flex flex-wrap'>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">Simple </label>
                                    </div>
                                    <div className="form-check me-5 mb-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label" for="flexRadioDefault2">Grievous </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Type of Injury </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Details of medical treatment </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <label for="" className="form-label">Details of permanent disability (if any) </label>
                                <input type="text" className="form-control" id="" placeholder="Description..." />
                            </div>
                            <div className="col-12 input-space">
                                <div className="table-responsive">                               
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60%" }}>Computation of compensation </th>
                                            <th scope="col" style={{ width: "40%" }}>Amount in Rs. </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Expenditure on the treatment </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Expenditure on conveyance </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Expenditure on special diet  </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Cost of nursing/attendant </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Cost of artificial limb </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of earning capacity </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of income </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Any other loss which may require any special treatment or aid to the injured for the rest of his life </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Compensation for mental and physical shock </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Pain and suffering </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of amenities of life </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Disfiguration </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of marriage prospects </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of earning, inconvenience, hardships,	disappointment, frustration, mental stress, dejectment and unhappiness in future
                                                life etc. </td>
                                            <td><input className="form-control" id="" placeholder="Enter Amount"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Total compensation </td>
                                            <td><p> ₹9999</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="drivingReason" className="form-label">If the Insurance Company does not admit the liability to pay the compensation, disclose
                                    the grounds on which the Insurance Company wants to contest the claim: </label>
                                <textarea className="form-control" id="drivingReason" rows="3" placeholder='Description...'></textarea>
                            </div>
                            <div className="col-12 input-space">
                                <p className='mb-0'>
                                    Verified at
                                    <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0'
                                        name="location" />
                                    on this
                                    <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0'
                                        name="day" />
                                    day of
                                    <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0'
                                        name="month" />
                                    above report are true and correct. I am well conversant with the principles of computation of compensation and have applied the same to
                                    compute the compensation.
                                </p>
                            </div>
                            <div className="entry">
                                <p className='mb-32'>Documents to be attached: </p>
                            </div>
                            <div className="col-12 input-space">
                                <label htmlFor="fileUpload" className="form-label">Report of the Surveyor/Investigator </label><br />
                                <input
                                    type="file"
                                    ref={fileInputRef1}
                                    onChange={(e) => handleFileChange(e, setFileName1)}
                                    style={{ display: 'none' }}
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleUploadClick(fileInputRef1)}
                                >
                                    <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                                </button>
                                {fileName1 && (
                                    <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                                )}
                            </div>
                            <div className="col-md-6 input-space mb-0">
                            <label className="form-label">DESIGNATED OFFICER </label>
                            {<SignatureComponent/>}
                            </div>
                        </form>
                        <div className="col-12 mt-80">
                            <Link to={"/FormTwelve"} type="submit" className="btn btn-sec">
                                <i className="fa-solid fa-check ne-2"></i> Save and Submit
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormEleven