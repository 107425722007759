import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureComponent from './SignatureComponent';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormSixA = () => {
    
    const fileInputRef0 = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
    const fileInputRef5 = useRef(null);
    const fileInputRef6 = useRef(null);

    const [fileName0, setFileName0] = useState('');
    const [fileName1, setFileName1] = useState('');
    const [fileName2, setFileName2] = useState('');
    const [fileName3, setFileName3] = useState('');
    const [fileName4, setFileName4] = useState('');
    const [fileName5, setFileName5] = useState('');
    const [fileName6, setFileName6] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Victim's Form - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Victim's Form</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of Accident</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Time of Accident</label>
                            <input type="time" className="form-control" id="" value="10:10"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Place of Accident</label>
                            <input type="text" className="form-control" id="" placeholder="Gandhinagar"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Nature of case</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Simple Injury </label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Grievous Injury</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Fatal</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Damage/loss of the property</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label" for="flexRadioDefault5">Any other loss/injury</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Registration Number of the offending vehicle</label>
                            <input type="text" className="form-control" id="" placeholder="001"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Owner Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Driver Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Insurance Details</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Insurance Policy No.</label>
                            <input type="text" className="form-control" id="" placeholder="11001100"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Insurance Policy</label>
                            <input type="text" className="form-control" id="" placeholder="10 Year"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Insurance Company</label>
                            <input type="text" className="form-control" id="" placeholder="RK Insurance Company"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Death Case</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of the deceased</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father's Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age / Date of Birth</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of death</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Gender of the deceased</label>
                            <input type="text" className="form-control" id="" placeholder="Male"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Marital status of the deceased</label>
                            <input type="text" className="form-control" id="" placeholder="Marital status"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Occupation of the deceased</label>
                            <input type="text" className="form-control" id="" placeholder="Dimond"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">If the deceased was employed, give the name and address of the employer</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Income of the deceased</label>
                            <input type="text" className="form-control" id="" placeholder="Income of the deceased"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the deceased was assessed to Income Tax <i>( If yes, file the copy of Income Tax Returns for the last three years )</i></label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault6" />
                                    <label className="form-check-label" for="flexRadioDefault6">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault7" />
                                    <label className="form-check-label" for="flexRadioDefault7">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the deceased was the sole earning member of the family</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault8" />
                                    <label className="form-check-label" for="flexRadioDefault8">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault9" />
                                    <label className="form-check-label" for="flexRadioDefault9">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Details of medical treatment given to the deceased, prior to death. Give details of medical expenses incurred</label>
                            <TextEditor />
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Whether the victim	got reimbursement of medical expenses from his employer or under a Mediclaim policy or under any government cashless treatment scheme or government insurance scheme <i> If yes, provide details</i></label>
                            <TextEditor />
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Name, Age, Gender, Relation and Marital Status of Legal Representatives of the deceased</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>1)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age / Date of Birth</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Gender</label>
                            <input type="text" className="form-control" id="" placeholder="Male"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Relation</label>
                            <input type="text" className="form-control" id="" placeholder="Relation"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Marital Status</label>
                            <input type="text" className="form-control" id="" placeholder="Marital Status"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>2)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age / Date of Birth</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Gender</label>
                            <input type="text" className="form-control" id="" placeholder="Male"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Relation</label>
                            <input type="text" className="form-control" id="" placeholder="Relation"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Marital Status</label>
                            <input type="text" className="form-control" id="" placeholder="Marital Status"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Name, Contact Number and Address of Legal Representatives of the deceased</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>1)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Contact Number</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Present Address as well as Permanent Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>2)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Contact Number</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Present Address as well as Permanent Address</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>In case of children below the age of 18 years</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>1)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Child</label>
                            <input type="text" className="form-control" id="" placeholder="Chintu Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Details of school and class of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Details of school..."/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Annual School fee</label>
                            <input type="text" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Approximate expenditure of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Approximate expenditure..."/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>2)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Child</label>
                            <input type="text" className="form-control" id="" placeholder="Chintu Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Details of school and class of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Details of school..."/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Annual School fee</label>
                            <input type="number" className="form-control" id="" placeholder="100000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Approximate expenditure of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Approximate expenditure..."/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Injury Case</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of the Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Rajesh Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Father's Name</label>
                            <input type="text" className="form-control" id="" placeholder="Pareshbhai Rathore"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Address of the Injured</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Contact No. of Injured</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age / Date of Birth</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Gender of the Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Male"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Marital status of the Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Marital status"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Occupation of the Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Dimond"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">If the Injured was employed, give the name and address of the employer</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Sadar, Bazar , Delehi'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Income of the Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Income of the deceased"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether	Injured	assessed to Income Tax <i>If yes, file the copy of Income Tax Returns for the last three years</i></label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault10" />
                                    <label className="form-check-label" for="flexRadioDefault10">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault11" />
                                    <label className="form-check-label" for="flexRadioDefault11">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Nature and description of Injury</label>
                            <input type="text" className="form-control" id="" placeholder="Nature and description of Injury"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Medical treatment taken by the Injured</label>
                            <input type="text" className="form-control" id="" placeholder="Medical treatment taken by the Injured"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Name of hospital and period of hospitalization</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Hospital Name</label>
                            <input type="text" className="form-control" id="" placeholder="Bims Hospital"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Period of Hospitalization </label>
                            <input type="text" className="form-control" id="" placeholder="Period of Hospitalization"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Doctor’s Name</label>
                            <input type="text" className="form-control" id="" placeholder="Dr. Vala"/>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Details of surgery(s), if undergone</label>
                            <TextEditor />
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether any permanent disability <i>If yes, give details</i></label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault12" />
                                    <label className="form-check-label" for="flexRadioDefault12">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault13" />
                                    <label className="form-check-label" for="flexRadioDefault13">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Details of the family of the Injured</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>1)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Chintu Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age / Date of Birth</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Gender</label>
                            <input type="text" className="form-control" id="" placeholder="Male"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Relation</label>
                            <input type="text" className="form-control" id="" placeholder="Son"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>2)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Chintu Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Age / Date of Birth</label>
                            <input type="date" className="form-control" id="" value='2024-09-22'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Gender</label>
                            <input type="text" className="form-control" id="" placeholder="Male"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Relation</label>
                            <input type="text" className="form-control" id="" placeholder="Son"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>In case of children below the age of 18 years</p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>1)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Child</label>
                            <input type="text" className="form-control" id="" placeholder="Chintu Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Details of school and class of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Details of school..."/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Annual School fee</label>
                            <input type="number" className="form-control" id="" placeholder="100000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Approximate expenditure of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Approximate expenditure..."/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>2)</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name of Child</label>
                            <input type="text" className="form-control" id="" placeholder="Chintu Rathore"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Details of school and class of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Details of school..."/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Annual School fee</label>
                            <input type="number" className="form-control" id="" placeholder="100000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Approximate expenditure of the child</label>
                            <input type="text" className="form-control" id="" placeholder="Approximate expenditure..."/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Pecuniary Losses suffered</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Expenditure on treatment</label>
                            <input type="text" className="form-control" id="" placeholder="Expenditure on treatment"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">If treatment is still continuing, give the estimate of expenditure likely to be incurred on future treatment</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Expenditure on conveyance, special diet, attendant charges etc.</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Loss of income</label>
                            <input type="number" className="form-control" id="" placeholder="100000"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Loss of earning capacity</label>
                            <input type="number" className="form-control" id="" placeholder="100000"/>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Any other pecuniary loss/damage</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Whether the injured got reimbursement of medical expenses from his employer or under a Mediclaim policy or under any government cashless treatment scheme or government insurance scheme <i>If yes, provide details</i></label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault14" />
                                    <label className="form-check-label" for="flexRadioDefault14">Yes</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault15" />
                                    <label className="form-check-label" for="flexRadioDefault15">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Value of loss/ damage to the property</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Any additional information</label>
                            <textarea class="form-control" id="" rows="3" placeholder='Description...'></textarea>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Brief description of the accident</label>
                            <TextEditor />
                        </div>
                        <div className="col-12 input-space">
                            <label for="" className="form-label">Compensation claimed</label>
                            <input type="text" className="form-control" id="" placeholder="Compensation claimed"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Documents to be submitted </p>
                        </div>
                        <div className="entry">
                            <p className='mb-3'>In Death Cases : </p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">1. Death certificate</label><br />
                            <label className="form-label">2. Proof of age of the deceased which may be in form of (a) Birth Certificate; (b) School Certificate; (c) Certificate from Gram Panchayat (in case of illiterate); (d) Aadhar Card etc.</label><br />
                            <label className="form-label">3. Proof of Occupation and Income of the deceased which may be in form of (a) Pay slip/salary certificate (salaried employee) (b) Bank statements of the last six months (c) Income tax Returns for last three years (d) Balance Sheet, etc.</label><br />
                            <label className="form-label">4. Proof of the legal representatives of the deceased such as ration card, passport etc.</label><br />
                            <label className="form-label">5. In case of legal heirs below the age of 18, copy of school ID, proof of school fee, proof of other expenses/expenditure of the children.</label><br />
                            <label className="form-label">6. Treatment record, medical bills and other expenditure prior to death</label><br />
                            <label className="form-label">7. Bank Account no. of the legal representatives of the deceased near the place of their residence with name and address of the bank along with the necessary endorsement</label><br />
                            <label className="form-label">8. Proof of reimbursement of medical expenses by employer or under a Mediclaim policy, if taken</label><br />
                            <label className="form-label">9. Any other document</label><br />
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} multiple/>
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                        <div className="entry">
                            <p className='mb-3'>In Injury Cases : </p>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">1. Multi angle photographs of the injured</label><br />
                            <label className="form-label">2. Proof of age of the injured which may be in form of (a) Birth Certificate; (b) School Certificate; (c) Certificate from Gram Panchayat (in case of illiterate); (d) Aadhar Card etc.</label><br />
                            <label className="form-label">3. Proof of Occupation and Income of the injured which may be in form of (a) Pay slip/salary certificate (salaried employee) (b) Bank statements of the last six months (c) Income tax Returns for the last three years (d) Balance Sheet, etc.</label><br />
                            <label className="form-label">4. Treatment record, medical bills and other expenditure. In case of continuing treatment give proof of future medical expenditure.</label><br />
                            <label className="form-label">5. Proof of absence from work where loss of income on account of injury is being claimed, which may be in the form of (a) Certificate from the employer; (b) Extracts from the attendance register.</label><br />
                            <label className="form-label">6. In case of legal heirs below the age of 18, copy of school ID, proof of school fee, proof of other expenses/expenditure of the children</label><br />
                            <label className="form-label">7. Bank Account no. of the injured near the place of his residence with name and address of the bank along with the necessary endorsement</label><br />
                            <label className="form-label">8. Proof of reimbursement of medical expenses by employer or under a Mediclaim policy, if taken</label><br />
                            <label className="form-label">9. Any other document</label><br />
                            <input type="file" ref={fileInputRef2} onChange={(e) => handleFileChange(e, setFileName2)} style={{ display: 'none' }} multiple/>
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef2)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName2 && (
                                <p className="mt-2 file-pre">Selected file: {fileName2}</p>
                            )}
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">Photograph</label><br />
                            <input type="file" ref={fileInputRef3} onChange={(e) => handleFileChange(e, setFileName3)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef3)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName3 && (
                                <p className="mt-2 file-pre">Selected file: {fileName3}</p>
                            )}
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Verification :</p>
                        </div>
                        <div className="col-12 input-space">
                            <p className='mb-0'>
                                Verified at
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="location" value={formData.location} onChange={handleChange} />
                                on this
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="day" value={formData.day} onChange={handleChange} />
                                day of
                                <input type="text" className='p-0 border-top-0 border-end-0 border-start-0 w-auto mx-2 rounded-0' name="month" value={formData.month} onChange={handleChange} />
                                that the contents of the above Form are true to my knowledge and the
                                documents attached are true copies of their originals.
                            </p>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Name and signature of the injured/legal representative of deceased</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Name</label>
                            <input type="text" className="form-control" id="" placeholder="Mukesh Rao"/>
                        </div>
                        <div className="col-12 input-space">
                            <label className="form-label">Upload Photograph</label><br />
                            <input type="file" ref={fileInputRef4} onChange={(e) => handleFileChange(e, setFileName4)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef4)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName4 && (
                                <p className="mt-2 file-pre">Selected file: {fileName4}</p>
                            )}
                        </div>
                        <div className="col-md-6 input-space mb-0">
                            <label className="form-label">Signature</label>
                            {<SignatureComponent/>}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormSixB"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormSixA