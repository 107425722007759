import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const FormEight = () => {
    
    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick = (inputRef) => {
        inputRef.current.click();
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [formData, setFormData] = useState({
        location: '',
        day: '',
        month: '',
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
  return (
      <div>
      <Helmet>
          <title>Site Plan - FIR Management System</title>
      </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>Site Plan</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">FIR No</label>
                            <input type="text" className="form-control" id="" placeholder="84721"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Under Section </label>
                            <select id="inputState" className="form-select">
                            <option selected>154 cr pc</option>
                            <option>154 cr pc</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="inputState" className="form-label">Police Station</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar, Bazar , Delehi</option>
                            <option>Sadar, Bazar , Delehi</option>
                            </select>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date of preparation of site plan</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Type of collision (collision from)</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">Hit from back</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">Vehicle to pedestrian</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label" for="flexRadioDefault3">Run-off road</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label" for="flexRadioDefault4">Vehicle overturn</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label" for="flexRadioDefault5">Head on collision</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                    <label className="form-check-label" for="flexRadioDefault6">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 input-space-check">
                            <label for="inputEmail4" className="form-label">Road direction</label>
                            <div className='d-flex flex-wrap'>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault7" />
                                    <label className="form-check-label" for="flexRadioDefault7">One-way</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault8" />
                                    <label className="form-check-label" for="flexRadioDefault8">Two-way</label>
                                </div>
                                <div className="form-check me-5 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault9" />
                                    <label className="form-check-label" for="flexRadioDefault9">Others (Specify)</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">No. of lanes</label>
                            <input type="number" className="form-control" id="" placeholder='10'/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Width of road</label>
                            <input type="text" className="form-control" id="" placeholder="20 Fit"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Place of accident</label>
                            <input type="text" className="form-control" id="" placeholder="Gandhinagar"/>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">Detailed Site Plan with road and junction name, direction and location of vehicle(s) on the road</label>
                            <TextEditor />
                        </div>
                        <div className="entry">
                            <p className='mb-32'>S.H.O./I.O</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.I.S. No.</label>
                            <input type="number" className="form-control" id="" placeholder="0001"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Phone No.</label>
                            <input type="number" className="form-control" id="" placeholder="+919999999999"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">P.S.</label>
                            <input type="text" className="form-control" id="" placeholder="Sadar, Bazar , Delehi"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-12 input-space mb-0">
                            <label className="form-label">Documents to be attached</label><br />
                            <input type="file" ref={fileInputRef1} onChange={(e) => handleFileChange(e, setFileName1)} style={{ display: 'none' }} />
                            <button type="button" className="btn btn-primary" onClick={() => handleUploadClick(fileInputRef1)} >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/FormNine"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default FormEight