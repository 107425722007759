import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextEditor from './TextEditor';
import { Helmet } from 'react-helmet';

const GeneralDiaryGD = () => {

    const fileInputRef1 = useRef(null);

    const [fileName1, setFileName1] = useState('');

    const handleUploadClick1 = () => {
        fileInputRef1.current.click();
    };

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName1(file.name);
        }
    };
    
  return (
      <div>
        <Helmet>
            <title>General Diary GD - FIR Management System</title>
        </Helmet>
          <div className='container'>
            <div className='pt-3 text-end'>
                <a href={require('../Image/Form.docx')} download="Form.docx" className="btn btn-primary"><i class="fa-solid fa-download me-2"></i> Download </a>
            </div>
            <div className="forms py-100">
                <div className="edit-event">
                    <div className="form-title">
                        <h1 className='mb-0'>General Diary GD</h1>
                    </div>
                </div>
                <div className="main-form">
                    <form className="row">
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="inputState" className="form-label">District</label>
                            <select id="inputState" className="form-select">
                            <option selected>North (Delhi)</option>
                            <option>North (Delhi)</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="inputState" className="form-label">P.S./Section</label>
                            <select id="inputState" className="form-select">
                            <option selected>Sadar Bazar</option>
                            <option>Sadar Bazar</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="" className="form-label">GD No</label>
                            <input type="text" className="form-control" id="" placeholder="0040A"/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="" className="form-label">Date</label>
                            <input type="date" className="form-control" id="" value="2024-09-21"/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 input-space">
                            <label for="" className="form-label">Time</label>
                            <input type="time" className="form-control" id="" placeholder="0040A"/>
                        </div>
                        <div className="entry">
                            <p className='mb-32'>Entry Made For :-</p>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">Officer Rank</label>
                            <input type="text" className="form-control" id="" placeholder="SUB-INSPECTOR/SHARMILA YADAV/16190535"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">GD Type</label>
                            <input type="text" className="form-control" id="" placeholder="PCR Call"/>
                        </div>
                        <div className="col-md-6 input-space">
                            <label for="" className="form-label">GD Subject</label>
                            <input type="text" className="form-control" id="" placeholder="Inbound and outbound PCR Call"/>
                        </div>
                        <div className="col-12 input-space">
                        <label for="" className="form-label">GD Brief</label>
                            <TextEditor />
                        </div>
                        <div className="col-12">
                            <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={handleFileChange1}
                                style={{ display: 'none' }}
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleUploadClick1}
                            >
                                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i> Upload
                            </button>
                            {fileName1 && (
                                <p className="mt-2 file-pre">Selected file: {fileName1}</p>
                            )}
                        </div>
                    </form>
                    <div className="col-12 mt-80">
                        <Link to={"/ArrestMemo"} type="submit" className="btn btn-sec">
                            <i className="fa-solid fa-check ne-2"></i> Save and Submit
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </div>
  )
}

export default GeneralDiaryGD